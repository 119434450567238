import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { ChevronLeft, Search, Trash2, Plus, Edit3 } from 'lucide-react';
import { v4 as uuidv4 } from 'uuid';
import { AccountGroup, SplitRule, SplitGroup, FriendlyNameMapping } from '../../types';
import SplitGroupPreview from '../SplitGroupPreview';
import NewSplitRuleModal from '../NewSplitRuleModal';
import EditFriendlyNameModal from '../EditFriendlyNameModal';
import { useS3Data } from '../../hooks/useS3Data';
import { getFriendlyName, updateFriendlyName } from '../../utils/friendlyNames';

interface SplitAccountsProps {
  previewOpen: boolean;
  setPreviewOpen: (open: boolean) => void;
  accountGroups: AccountGroup[];
  splitRules: SplitRule[];
  setSplitRules: (rules: SplitRule[]) => void;
  friendlyNames: FriendlyNameMapping[];
  setFriendlyNames: (mappings: FriendlyNameMapping[]) => void;
}

const SplitAccounts: React.FC<SplitAccountsProps> = ({
  previewOpen,
  setPreviewOpen,
  accountGroups,
  splitRules,
  setSplitRules,
  friendlyNames,
  setFriendlyNames
}) => {
  const { driverOptions, error: s3Error, loading } = useS3Data();
  const [selectedRule, setSelectedRule] = useState<SplitRule | null>(null);
  const [selectedGroup, setSelectedGroup] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [showNewRuleModal, setShowNewRuleModal] = useState(false);
  const [preselectedGroup, setPreselectedGroup] = useState<string | null>(null);
  const [editingGroup, setEditingGroup] = useState<string | null>(null);

  const handleClickOutside = useCallback((e: React.MouseEvent) => {
    if (
      (e.target as HTMLElement).closest('.rule-row') === null &&
      (e.target as HTMLElement).closest('.group-item') === null
    ) {
      setSelectedRule(null);
      setSelectedGroup(null);
    }
  }, []);

  const applySplitRules = useCallback(
    (groups: AccountGroup[], rules: SplitRule[]): SplitGroup[] => {
      const splitGroups: SplitGroup[] = [];

      groups.forEach((originalGroup) => {
        const rule = rules.find((r) => r.accountGroupName === originalGroup.name);
        if (rule) {
          const midPoint = Math.floor(originalGroup.accounts.length / 2);

          const primaryGroup: AccountGroup = {
            id: uuidv4(),
            name: rule.newNamePrimary,
            accounts: originalGroup.accounts.slice(0, midPoint),
            rule: rule,
            sortOrder: originalGroup.sortOrder,
          };

          const secondaryGroup: AccountGroup = {
            id: uuidv4(),
            name: rule.newNameSecondary,
            accounts: originalGroup.accounts.slice(midPoint),
            rule: rule,
            sortOrder: originalGroup.sortOrder,
          };

          splitGroups.push({
            id: uuidv4(),
            originalName: originalGroup.name,
            primaryGroup,
            secondaryGroup,
            rule: rule,
            sortOrder: originalGroup.sortOrder,
          });
        }
      });

      return splitGroups.sort((a, b) => a.sortOrder - b.sortOrder);
    },
    []
  );

  const handleUpdateFriendlyName = (groupName: string, friendlyName: string) => {
    setFriendlyNames(prevNames => updateFriendlyName(groupName, friendlyName, prevNames));
  };

  const addRule = (newRuleData: Omit<SplitRule, 'id'>) => {
    const newRule: SplitRule = {
      ...newRuleData,
      id: uuidv4(),
    };
    setSplitRules((prev) => [...prev, newRule]);
    setShowNewRuleModal(false);
    setSelectedRule(newRule);
    setPreselectedGroup(null);
  };

  const deleteRule = (ruleId: string) => {
    setSplitRules((prev) => prev.filter((r) => r.id !== ruleId));
    if (selectedRule?.id === ruleId) {
      setSelectedRule(null);
      setSelectedGroup(null);
    }
  };

  const handleRuleClick = (rule: SplitRule) => {
    if (selectedRule?.id === rule.id) {
      setSelectedRule(null);
      setSelectedGroup(null);
    } else {
      setSelectedRule(rule);
      setSelectedGroup(null);
      if (!previewOpen) {
        setPreviewOpen(true);
      }
    }
  };

  const handleGroupClick = (groupName: string) => {
    const rule = splitRules.find(
      (r) =>
        r.accountGroupName === groupName ||
        r.newNamePrimary === groupName ||
        r.newNameSecondary === groupName
    );

    if (rule) {
      setSelectedRule(rule);
      setSelectedGroup(groupName);
    } else {
      setSelectedGroup(groupName);
      setSelectedRule(null);
    }
  };

  const handleCreateRule = (groupName: string) => {
    setPreselectedGroup(groupName);
    setShowNewRuleModal(true);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="text-lg text-gray-600">Loading driver options...</div>
      </div>
    );
  }

  if (s3Error) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="text-lg text-red-600">Error loading driver options: {s3Error}</div>
      </div>
    );
  }

  return (
    <div className="flex h-full relative" onClick={handleClickOutside}>
      <div className="flex-1 p-6">
        <div className="mb-6">
          <h2 className="text-xl font-semibold mb-2">Split Accounts</h2>
          <p className="text-gray-600">
            Create rules to split account groups into smaller segments based on specific drivers.
          </p>
        </div>

        <div className="h-[calc(100vh-220px)] flex flex-col bg-white rounded-lg shadow">
          <div className="p-4 border-b border-gray-200">
            <div className="flex justify-between items-center mb-4">
              <div className="relative flex-1 max-w-md">
                <input
                  type="text"
                  placeholder="Search rules..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                />
                <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
              </div>
              <button
                onClick={() => setShowNewRuleModal(true)}
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
              >
                Add Rule
              </button>
            </div>
          </div>

          <div className="flex-1 overflow-auto">
            <table className="w-full">
              <thead className="bg-gray-50 sticky top-0">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Original Group
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Driver
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    New Name Primary
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    New Name Secondary
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {splitRules.map((rule) => (
                  <tr
                    key={rule.id}
                    onClick={() => handleRuleClick(rule)}
                    className={`rule-row transition-colors duration-150 cursor-pointer ${
                      selectedRule?.id === rule.id ? 'bg-blue-50' : 'hover:bg-gray-50'
                    }`}
                  >
                    <td className="px-6 py-4">
                      <div className="flex items-center">
                        <span>{rule.accountGroupName}</span>
                        {getFriendlyName(rule.accountGroupName, friendlyNames) && (
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800 ml-2">
                            {getFriendlyName(rule.accountGroupName, friendlyNames)}
                          </span>
                        )}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <select
                        value={rule.driver}
                        onChange={(e) => {
                          const updatedDriver = e.target.value;
                          setSplitRules((prevRules) =>
                            prevRules.map((r) =>
                              r.id === rule.id ? { ...r, driver: updatedDriver } : r
                            )
                          );
                        }}
                        onClick={(e) => e.stopPropagation()}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        title={driverOptions.find(d => d.value === rule.driver)?.hover_description}
                      >
                        {driverOptions.map((driver) => (
                          <option 
                            key={driver.value} 
                            value={driver.value}
                            title={driver.hover_description}
                          >
                            {driver.label}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td className="px-6 py-4">
                      <input
                        type="text"
                        value={rule.newNamePrimary}
                        onChange={(e) => {
                          const newNamePrimary = e.target.value;
                          setSplitRules((prevRules) =>
                            prevRules.map((r) =>
                              r.id === rule.id ? { ...r, newNamePrimary } : r
                            )
                          );
                        }}
                        onClick={(e) => e.stopPropagation()}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                      />
                    </td>
                    <td className="px-6 py-4">
                      <input
                        type="text"
                        value={rule.newNameSecondary}
                        onChange={(e) => {
                          const newNameSecondary = e.target.value;
                          setSplitRules((prevRules) =>
                            prevRules.map((r) =>
                              r.id === rule.id ? { ...r, newNameSecondary } : r
                            )
                          );
                        }}
                        onClick={(e) => e.stopPropagation()}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                      />
                    </td>
                    <td className="px-6 py-4">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteRule(rule.id);
                        }}
                        className="text-red-600 hover:text-red-900"
                      >
                        <Trash2 className="w-5 h-5" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {!previewOpen && (
        <button
          onClick={() => setPreviewOpen(true)}
          className="fixed right-0 top-1/2 transform -translate-y-1/2 bg-white border border-gray-200 rounded-l-lg p-2 shadow-lg z-[999] flex flex-col items-center justify-center min-h-[80px] w-[40px]"
        >
          <ChevronLeft className="w-5 h-5" />
          <span className="writing-mode-vertical text-sm mt-2">Preview Results</span>
        </button>
      )}

      {previewOpen && (
        <div className="fixed top-0 right-0 w-[400px] h-full">
          <SplitGroupPreview
            accountGroups={accountGroups}
            splitGroups={applySplitRules(accountGroups, splitRules)}
            selectedGroup={selectedGroup}
            onGroupClick={handleGroupClick}
            onCreateRule={handleCreateRule}
            onDeleteRule={deleteRule}
            onClose={() => setPreviewOpen(false)}
            filteredByRule={selectedRule}
            friendlyNames={friendlyNames}
            onUpdateFriendlyName={handleUpdateFriendlyName}
          />
        </div>
      )}

      {showNewRuleModal && (
        <NewSplitRuleModal
          onClose={() => {
            setShowNewRuleModal(false);
            setPreselectedGroup(null);
          }}
          onSave={addRule}
          accountGroups={accountGroups}
          preselectedGroup={preselectedGroup}
          driverOptions={driverOptions}
        />
      )}

      {editingGroup && (
        <EditFriendlyNameModal
          groupName={editingGroup}
          currentFriendlyName={getFriendlyName(editingGroup, friendlyNames) || ''}
          onSave={(friendlyName) => {
            handleUpdateFriendlyName(editingGroup, friendlyName);
            setEditingGroup(null);
          }}
          onClose={() => setEditingGroup(null)}
        />
      )}
    </div>
  );
};

export default SplitAccounts;