// src/components/EditFriendlyNameModal.tsx

import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';

interface EditFriendlyNameModalProps {
  groupName: string;
  currentFriendlyName: string;
  onSave: (friendlyName: string) => void;
  onClose: () => void;
}

const EditFriendlyNameModal: React.FC<EditFriendlyNameModalProps> = ({
  groupName,
  currentFriendlyName,
  onSave,
  onClose,
}) => {
  const [friendlyName, setFriendlyName] = useState(currentFriendlyName);

  useEffect(() => {
    setFriendlyName(currentFriendlyName);
  }, [currentFriendlyName]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSave(friendlyName.trim());
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[1000]">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-md">
        <div className="flex justify-between items-center p-4 border-b">
          <h3 className="text-lg font-semibold">Edit Friendly Name</h3>
          <button onClick={onClose} className="p-1 hover:bg-gray-100 rounded-full">
            <X className="w-5 h-5" />
          </button>
        </div>
        <form onSubmit={handleSubmit} className="p-4 space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Account Group Name
            </label>
            <input
              type="text"
              value={groupName}
              disabled
              className="w-full px-3 py-2 border border-gray-300 rounded-md bg-gray-100 cursor-not-allowed"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Friendly Name
            </label>
            <input
              type="text"
              value={friendlyName}
              onChange={(e) => setFriendlyName(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Enter friendly name"
            />
          </div>
          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditFriendlyNameModal;
