import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { AllocationRule, AccountGroup } from '../types';

interface DriverOption {
  value: string;
  label: string;
  hover_description: string;
}

interface NewAllocationRuleModalProps {
  onClose: () => void;
  onSave: (rule: Omit<AllocationRule, 'id'>) => void;
  accountGroups: AccountGroup[];
  driverOptions: DriverOption[];
  preselectedGroup?: string | null;
}

const NewAllocationRuleModal: React.FC<NewAllocationRuleModalProps> = ({
  onClose,
  onSave,
  accountGroups,
  driverOptions,
  preselectedGroup
}) => {
  const [rule, setRule] = useState<Omit<AllocationRule, 'id'>>({
    'Account Group Name': preselectedGroup || '',
    'Driver': driverOptions[0]?.value || '',
    'Destination': null
  });

  useEffect(() => {
    if (preselectedGroup) {
      setRule(prev => ({
        ...prev,
        'Account Group Name': preselectedGroup
      }));
    }
  }, [preselectedGroup]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSave(rule);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[1000]">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-md">
        <div className="flex justify-between items-center p-4 border-b">
          <h3 className="text-lg font-semibold">Create New Allocation Rule</h3>
          <button onClick={onClose} className="p-1 hover:bg-gray-100 rounded-full">
            <X className="w-5 h-5" />
          </button>
        </div>
        <form onSubmit={handleSubmit} className="p-4 space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Account Group
            </label>
            <select
              value={rule['Account Group Name']}
              onChange={(e) => setRule(prev => ({ ...prev, 'Account Group Name': e.target.value }))}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              required
            >
              <option value="">Select Account Group</option>
              {accountGroups.map(group => (
                <option key={group.name} value={group.name}>
                  {group.name} {group.friendlyName ? `(${group.friendlyName})` : ''}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Driver</label>
            <select
              value={rule.Driver}
              onChange={(e) => {
                const newDriver = e.target.value;
                setRule(prev => ({ 
                  ...prev, 
                  Driver: newDriver,
                  Destination: newDriver === 'sent_to' ? prev.Destination : null
                }));
              }}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 group"
              required
            >
              <option value="">Select Driver</option>
              {driverOptions.map(driver => (
                <option 
                  key={driver.value} 
                  value={driver.value}
                  title={driver.hover_description}
                >
                  {driver.label}
                </option>
              ))}
            </select>
            {/* Hover description tooltip */}
            <div className="mt-1 text-sm text-gray-500">
              {driverOptions.find(d => d.value === rule.Driver)?.hover_description}
            </div>
          </div>
          {rule.Driver === 'sent_to' && (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Destination
              </label>
              <input
                type="text"
                value={rule.Destination || ''}
                onChange={(e) => setRule(prev => ({ 
                  ...prev, 
                  Destination: e.target.value || null 
                }))}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Enter destination"
                required={rule.Driver === 'sent_to'}
              />
            </div>
          )}
          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
            >
              Create Rule
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewAllocationRuleModal;