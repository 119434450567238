import React, { useState, useEffect } from 'react';
import { ChevronRight, ChevronLeft, Trash2, AlertCircle, Search, Download, Upload, HelpCircle } from 'lucide-react';
import StageIndicator from './components/StageIndicator';
import GroupAccounts from './components/stages/GroupAccounts';
import SplitGroups from './components/stages/SplitAccounts';
import IdentifyReservations from './components/stages/IdentifyReservations';
import AllocateMonies from './components/stages/AllocateMonies';
import HelpModal from './components/HelpModal';
import { AccountGroup, Rule, SplitRule, IdentificationRule, AllocationRule, Account, FriendlyNameMapping } from './types';
import accountData from './data/accountData.json';
import { v4 as uuidv4 } from 'uuid';
import { groupAccounts } from './utils/accountGrouping';

function App() {
  const [currentStage, setCurrentStage] = useState(1);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [accounts, setAccounts] = useState<Account[]>(accountData["Stage 1 - Group Accounts"].account_information);
  
  // Stage 1 rules
  const [rules, setRules] = useState<Rule[]>(() => 
    accountData["Stage 1 - Group Accounts"].PART_1_RULES.map(rule => ({
      ...rule,
      id: uuidv4()
    }))
  );

  // Stage 2 rules and account groups
  const [splitRules, setSplitRules] = useState<SplitRule[]>(() =>
    accountData["Stage 2 - Split Groups"].PART_2_RULES.map(rule => ({
      id: uuidv4(),
      accountGroupName: rule["Account Group Name"],
      driver: rule["Driver"],
      newNamePrimary: rule["New Name Primary"],
      newNameSecondary: rule["New Name Secondary"]
    }))
  );

  // Stage 3 rules
  const [identificationRules, setIdentificationRules] = useState<IdentificationRule[]>(() =>
    (accountData["Stage 3 - Identify Reservations"]?.PART_3_RULES || []).map(rule => ({
      id: uuidv4(),
      accountGroupName: rule["Account Group Name"],
      testTable: rule["Test_Table"],
      detectionIndicator: rule["Detection Indicator"],
      testColumn: rule["Test Column"],
      testValue: rule["Test Value"]
    }))
  );

  // Stage 4 rules
  const [allocationRules, setAllocationRules] = useState<AllocationRule[]>(() =>
    (accountData["Stage 4 - Allocate Monies"]?.PART_4_RULES || []).map(rule => ({
      id: uuidv4(),
      accountGroupName: rule["Account Group Name"],
      driver: rule["Driver"],
      destination: rule["Destination"] || null
    }))
  );

  // Friendly name mappings
  const [friendlyNames, setFriendlyNames] = useState<FriendlyNameMapping[]>(() => 
    accountData.currentConfiguration?.friendlyNames || []
  );

  // Account groups with friendly names
  const [accountGroups, setAccountGroups] = useState<AccountGroup[]>(() => {
    return groupAccounts(accounts, rules);
  });

  // Update account groups whenever Stage 1 rules or accounts change
  useEffect(() => {
    const updatedGroups = groupAccounts(accounts, rules);
    setAccountGroups(updatedGroups);
  }, [rules, accounts]);

  const handleExport = () => {
    const dataToExport = {
      ...accountData,
      currentConfiguration: {
        currentStage,
        previewOpen,
        rules,
        splitRules,
        identificationRules,
        allocationRules,
        friendlyNames,
        accountGroups
      },
      "Stage 1 - Group Accounts": {
        ...accountData["Stage 1 - Group Accounts"],
        account_information: accounts,
        PART_1_RULES: rules
      },
      "Stage 2 - Split Groups": {
        ...accountData["Stage 2 - Split Groups"],
        PART_2_RULES: splitRules.map(rule => ({
          "Account Group Name": rule.accountGroupName,
          "Driver": rule.driver,
          "New Name Primary": rule.newNamePrimary,
          "New Name Secondary": rule.newNameSecondary
        }))
      },
      "Stage 3 - Identify Reservations": {
        ...accountData["Stage 3 - Identify Reservations"],
        PART_3_RULES: identificationRules
      },
      "Stage 4 - Allocate Monies": {
        ...accountData["Stage 4 - Allocate Monies"],
        PART_4_RULES: allocationRules
      }
    };
    
    const blob = new Blob([JSON.stringify(dataToExport, null, 2)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'account-configuration.json';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleImport = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const importedData = JSON.parse(e.target?.result as string);
          
          // Import current configuration
          if (importedData.currentConfiguration) {
            setCurrentStage(importedData.currentConfiguration.currentStage || 1);
            setPreviewOpen(importedData.currentConfiguration.previewOpen || false);
            setFriendlyNames(importedData.currentConfiguration.friendlyNames || []);
          }

          // Import Stage 1 data
          if (importedData["Stage 1 - Group Accounts"]) {
            setAccounts(importedData["Stage 1 - Group Accounts"].account_information);
            setRules(importedData["Stage 1 - Group Accounts"].PART_1_RULES.map((rule: Rule) => ({
              ...rule,
              id: uuidv4()
            })));
          }

          // Import Stage 2 data
          if (importedData["Stage 2 - Split Groups"]) {
            setSplitRules(importedData["Stage 2 - Split Groups"].PART_2_RULES.map((rule: any) => ({
              id: uuidv4(),
              accountGroupName: rule["Account Group Name"],
              driver: rule["Driver"],
              newNamePrimary: rule["New Name Primary"],
              newNameSecondary: rule["New Name Secondary"]
            })));
          }

          // Import Stage 3 rules
          if (importedData["Stage 3 - Identify Reservations"]?.PART_3_RULES) {
            setIdentificationRules(importedData["Stage 3 - Identify Reservations"].PART_3_RULES.map((rule: IdentificationRule) => ({
              ...rule,
              id: uuidv4()
            })));
          }

          // Import Stage 4 rules
          if (importedData["Stage 4 - Allocate Monies"]?.PART_4_RULES) {
            setAllocationRules(importedData["Stage 4 - Allocate Monies"].PART_4_RULES.map((rule: AllocationRule) => ({
              ...rule,
              id: uuidv4()
            })));
          }
        } catch (error) {
          console.error('Error parsing imported file:', error);
          alert('Invalid file format. Please select a valid JSON file.');
        }
      };
      reader.readAsText(file);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      {/* Header */}
      <header className="bg-white border-b border-gray-200 px-6 py-4">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-semibold text-gray-800">Account Allocation Configuration</h1>
          <button
            onClick={() => setShowHelpModal(true)}
            className="flex items-center px-4 py-2 text-blue-600 hover:bg-blue-50 rounded-md transition-colors"
          >
            <HelpCircle className="w-5 h-5 mr-2" />
            Help
          </button>
        </div>
      </header>

      {/* Stage Indicator */}
      <div className="bg-white border-b border-gray-200 px-6 py-3">
        <StageIndicator 
          stages={[
            { id: 1, title: 'Group Accounts' },
            { id: 2, title: 'Split Groups' },
            { id: 3, title: 'Identify Reservations' },
            { id: 4, title: 'Allocate Monies' }
          ]} 
          currentStage={currentStage} 
          onStageClick={setCurrentStage}
        />
      </div>

      {/* Main Content */}
      <div className="relative flex flex-1">
        {/* Main Content Area */}
        <div className={`flex-1 transition-all duration-300 ${previewOpen ? 'mr-[400px]' : ''}`}>
          {currentStage === 1 && (
            <GroupAccounts 
              previewOpen={previewOpen}
              setPreviewOpen={setPreviewOpen}
              rules={rules}
              setRules={setRules}
              accounts={accounts}
              accountGroups={accountGroups}
            />
          )}
          {currentStage === 2 && (
            <SplitGroups 
              previewOpen={previewOpen}
              setPreviewOpen={setPreviewOpen}
              accountGroups={accountGroups}
              splitRules={splitRules}
              setSplitRules={setSplitRules}
              friendlyNames={friendlyNames}
              setFriendlyNames={setFriendlyNames}
            />
          )}
          {currentStage === 3 && (
            <IdentifyReservations 
              previewOpen={previewOpen}
              setPreviewOpen={setPreviewOpen}
              rules={identificationRules}
              setRules={setIdentificationRules}
              accountGroups={accountGroups}
              splitRules={splitRules}
              friendlyNames={friendlyNames}
            />
          )}
          {currentStage === 4 && (
            <AllocateMonies 
              previewOpen={previewOpen}
              setPreviewOpen={setPreviewOpen}
              rules={allocationRules}
              setRules={setAllocationRules}
              accountGroups={accountGroups}
              splitRules={splitRules}
              friendlyNames={friendlyNames}
            />
          )}
        </div>
      </div>

      {/* Import/Export Footer */}
      <footer className="bg-white border-t border-gray-200 px-6 py-4">
        <div className="flex items-center space-x-4">
          <button
            onClick={handleExport}
            className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
          >
            <Download className="w-4 h-4 mr-2" />
            Export Configuration
          </button>
          <label className="flex items-center px-4 py-2 bg-gray-600 text-white rounded-md hover:bg-gray-700 cursor-pointer">
            <Upload className="w-4 h-4 mr-2" />
            Import Configuration
            <input
              type="file"
              accept=".json"
              onChange={handleImport}
              className="hidden"
            />
          </label>
          <div className="text-sm text-gray-500 ml-auto">
            Stage {currentStage} of 4
          </div>
        </div>
      </footer>

      {/* Help Modal */}
      {showHelpModal && (
        <HelpModal 
          stage={currentStage}
          onClose={() => setShowHelpModal(false)}
        />
      )}
    </div>
  );
}

export default App;