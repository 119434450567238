import React from 'react';
import { Check, Circle } from 'lucide-react';

interface Stage {
  id: number;
  title: string;
}

interface StageIndicatorProps {
  stages: Stage[];
  currentStage: number;
  onStageClick: (stage: number) => void;
}

const StageIndicator: React.FC<StageIndicatorProps> = ({ 
  stages, 
  currentStage, 
  onStageClick 
}) => {
  return (
    <div className="flex items-center justify-center space-x-4">
      {stages.map((stage, index) => (
        <React.Fragment key={stage.id}>
          <button
            onClick={() => onStageClick(stage.id)}
            className={`flex items-center ${
              currentStage === stage.id
                ? 'text-blue-600'
                : currentStage > stage.id
                ? 'text-green-600'
                : 'text-gray-400'
            }`}
          >
            <span className="flex items-center justify-center w-8 h-8 border-2 rounded-full mr-2">
              {currentStage > stage.id ? (
                <Check className="w-5 h-5" />
              ) : (
                <span className={`text-sm font-medium`}>{stage.id}</span>
              )}
            </span>
            <span className={`text-sm font-medium ${
              currentStage === stage.id ? 'text-gray-900' : 'text-gray-500'
            }`}>
              {stage.title}
            </span>
          </button>
          {index < stages.length - 1 && (
            <div className={`w-16 h-[2px] ${
              currentStage > stage.id + 1
                ? 'bg-green-600'
                : 'bg-gray-200'
            }`} />
          )}
        </React.Fragment>
      ))}
    </div>
  );
}

export default StageIndicator;