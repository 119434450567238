import React, { useState, useEffect } from 'react';
import { Search, ChevronRight } from 'lucide-react';
import { Account, AccountGroup, Rule } from '../types';

interface AccountPreviewProps {
  groups: AccountGroup[];
  groupingBy: 'group' | 'account';
  onAccountClick: (accountId: number) => void;
  onGroupingChange: (grouping: 'group' | 'account') => void;
  selectedAccount: number | null;
  onClose: () => void;
  filteredByRule: Rule | null;
}

const AccountPreview: React.FC<AccountPreviewProps> = ({
  groups,
  groupingBy,
  onAccountClick,
  onGroupingChange,
  selectedAccount,
  onClose,
  filteredByRule,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [hoveredAccount, setHoveredAccount] = useState<number | null>(null);

  useEffect(() => {
    console.log('AccountPreview - filteredByRule changed:', filteredByRule);
  }, [filteredByRule]);

  const normalizeForSearch = (value: any): string => {
    return String(value).toLowerCase().trim();
  };

  const filterGroups = (groups: AccountGroup[]) => {
    return groups.map(group => ({
      ...group,
      accounts: group.accounts.filter(account => {
        // First apply search filter
        const searchLower = normalizeForSearch(searchTerm);
        
        // Create an array of all searchable fields
        const searchableFields = [
          normalizeForSearch(account['Account ID']),
          normalizeForSearch(account['Account Name']),
          normalizeForSearch(account['Business Segment Name']),
          normalizeForSearch(account['Segment 2 ID']),
          normalizeForSearch(account['Revenue Or Expense']),
          normalizeForSearch(group.name),
          group.friendlyName ? normalizeForSearch(group.friendlyName) : ''
        ];

        // Check if any field contains the search term
        const matchesSearch = searchableFields.some(field => field.includes(searchLower));

        // Then apply rule filter if one is selected
        const matchesRule = filteredByRule ? (
          account.appliedRule?.id === filteredByRule.id
        ) : true;

        return matchesSearch && matchesRule;
      }),
    })).filter(group => group.accounts.length > 0);
  };

  const filteredGroups = filterGroups(groups);

  // For account view, we need to maintain the group association
  const allAccountsWithGroups = groupingBy === 'account' 
    ? filteredGroups.flatMap(group => 
        group.accounts.map(account => ({
          account,
          groupName: group.name,
          groupFriendlyName: group.friendlyName
        }))
      ).sort((a, b) => {
        // Handle both numeric and string account IDs
        const aId = normalizeForSearch(a.account['Account ID']);
        const bId = normalizeForSearch(b.account['Account ID']);
        return aId.localeCompare(bId, undefined, { numeric: true, sensitivity: 'base' });
      })
    : [];

  const sortedGroups = groupingBy === 'group'
    ? filteredGroups.sort((a, b) => a.name.localeCompare(b.name))
    : [{
        name: 'All Accounts',
        accounts: allAccountsWithGroups.map(item => item.account)
      }];

  return (
    <div className="fixed top-0 right-0 h-full bg-white border-l border-gray-200 shadow-lg w-[400px] flex flex-col">
      {/* Header */}
      <div className="p-4 border-b border-gray-200">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">
            Preview Results
            {filteredByRule && (
              <span className="ml-2 text-sm text-blue-600">
                (Filtered by rule: {filteredByRule['Allocate Based On']} = {filteredByRule['Allocation Value']})
              </span>
            )}
          </h2>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
          >
            <ChevronRight className="w-5 h-5" />
          </button>
        </div>
        <div className="relative">
          <input
            type="text"
            placeholder="Search accounts by ID, name, segment..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
          <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
        </div>
      </div>

      {/* View Toggle */}
      <div className="px-4 py-2 border-b border-gray-200">
        <div className="flex space-x-2">
          <button
            onClick={() => onGroupingChange('group')}
            className={`px-3 py-1 rounded-md text-sm ${
              groupingBy === 'group'
                ? 'bg-blue-100 text-blue-700'
                : 'text-gray-600 hover:bg-gray-100'
            }`}
          >
            Group View
          </button>
          <button
            onClick={() => onGroupingChange('account')}
            className={`px-3 py-1 rounded-md text-sm ${
              groupingBy === 'account'
                ? 'bg-blue-100 text-blue-700'
                : 'text-gray-600 hover:bg-gray-100'
            }`}
          >
            Account View
          </button>
        </div>
      </div>

      {/* Account List */}
      <div className="flex-1 overflow-auto">
        {sortedGroups.map((group) => (
          <div key={group.name} className="border-b border-gray-200">
            {groupingBy === 'group' && (
              <div className="px-4 py-2 bg-gray-100 font-medium text-gray-700 flex items-center">
                <span>{group.name}</span>
                {group.isDefault && (
                  <span className="ml-2 px-2 py-0.5 text-xs bg-yellow-100 text-yellow-800 rounded-full">
                    Default Rule
                  </span>
                )}
                <span className="ml-2 text-sm text-gray-500">
                  ({group.accounts.length} accounts)
                </span>
              </div>
            )}
            <div className="divide-y divide-gray-100">
              {groupingBy === 'group' 
                ? group.accounts.map((account) => (
                    <AccountRow
                      key={account['Account ID']}
                      account={account}
                      groupName={group.name}
                      groupFriendlyName={group.friendlyName}
                      selectedAccount={selectedAccount}
                      hoveredAccount={hoveredAccount}
                      onAccountClick={onAccountClick}
                      setHoveredAccount={setHoveredAccount}
                      showGroupInfo={false}
                    />
                  ))
                : allAccountsWithGroups.map(({ account, groupName, groupFriendlyName }) => (
                    <AccountRow
                      key={account['Account ID']}
                      account={account}
                      groupName={groupName}
                      groupFriendlyName={groupFriendlyName}
                      selectedAccount={selectedAccount}
                      hoveredAccount={hoveredAccount}
                      onAccountClick={onAccountClick}
                      setHoveredAccount={setHoveredAccount}
                      showGroupInfo={true}
                    />
                  ))
              }
            </div>
          </div>
        ))}

        {filteredGroups.length === 0 && (
          <div className="p-4 text-center text-gray-500">
            No accounts match your search criteria
          </div>
        )}
      </div>
    </div>
  );
};

interface AccountRowProps {
  account: Account;
  groupName: string;
  groupFriendlyName?: string;
  selectedAccount: number | null;
  hoveredAccount: number | null;
  onAccountClick: (accountId: number) => void;
  setHoveredAccount: (accountId: number | null) => void;
  showGroupInfo: boolean;
}

const AccountRow: React.FC<AccountRowProps> = ({
  account,
  groupName,
  groupFriendlyName,
  selectedAccount,
  hoveredAccount,
  onAccountClick,
  setHoveredAccount,
  showGroupInfo
}) => {
  return (
    <div
      className="relative group"
    >
      <div
        className={`account-item px-4 py-2 cursor-pointer transition-colors duration-150 ${
          selectedAccount === account['Account ID'] ? 'bg-blue-50' : 'hover:bg-gray-50'
        }`}
        onClick={() => onAccountClick(account['Account ID'])}
        onMouseEnter={() => setHoveredAccount(account['Account ID'])}
        onMouseLeave={() => setHoveredAccount(null)}
      >
        <div className="flex justify-between items-center">
          <div>
            <div className="font-medium text-gray-900">
              {account['Account Name']}
            </div>
            <div className="text-sm text-gray-500">
              ID: {account['Account ID']} | Segment: {account['Segment 2 ID']}
              {showGroupInfo && (
                <span className="ml-2 text-blue-600">
                  Group: {groupName}
                  {groupFriendlyName && ` (${groupFriendlyName})`}
                </span>
              )}
            </div>
          </div>
          <div className={`text-sm px-2 flex items-center h-6 rounded ${
            account['Revenue Or Expense'] === 'Revenue' 
              ? 'bg-green-100 text-green-800' 
              : 'bg-blue-100 text-blue-800'
          }`}>
            {account['Revenue Or Expense']}
          </div>
        </div>
      </div>

      {/* Hover Tooltip */}
      {hoveredAccount === account['Account ID'] && (
        <div 
          className="fixed z-[1000] bg-white p-4 rounded-lg shadow-lg border border-gray-200 w-80"
          style={{
            left: 'auto',
            right: '420px',
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        >
          <div className="space-y-3">
            <h3 className="font-semibold text-gray-900">Rule Information</h3>
            
            <div>
              <div className="font-medium text-gray-900 mb-1">Applied Rule:</div>
              {account.appliedRule ? (
                <div className="text-gray-700 bg-blue-50 p-2 rounded">
                  <div>Type: {account.appliedRule['Allocate Based On']}</div>
                  <div>Value: {account.appliedRule['Allocation Value']}</div>
                  {account.appliedRule['Override'] && (
                    <div>Override: {account.appliedRule['Override']}</div>
                  )}
                </div>
              ) : (
                <div className="text-yellow-600 bg-yellow-50 p-2 rounded">
                  Default rule: Grouped by Segment 2 ID
                </div>
              )}
            </div>
            
            {account.otherMatchingRules && account.otherMatchingRules.length > 0 && (
              <div>
                <div className="font-medium text-gray-900 mb-1">Other Matching Rules:</div>
                <div className="space-y-2">
                  {account.otherMatchingRules.map((rule) => (
                    <div key={rule.id} className="text-gray-600 bg-gray-50 p-2 rounded">
                      <div className="text-sm">
                        <div>Type: {rule['Allocate Based On']}</div>
                        <div>Value: {rule['Allocation Value']}</div>
                        {rule['Override'] && (
                          <div>Override: {rule['Override']}</div>
                        )}
                        <div className="text-yellow-600 mt-1">
                          Not applied due to lower priority
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AccountPreview;