import React from 'react';
import { X } from 'lucide-react';

interface HelpModalProps {
  stage: number;
  onClose: () => void;
}

const HelpModal: React.FC<HelpModalProps> = ({ stage, onClose }) => {
  const [content, setContent] = React.useState<string>('');

  React.useEffect(() => {
    fetch(`/help/stage${stage}.html`)
      .then(response => response.text())
      .then(html => {
        // Extract only the body content and its styles
        const doc = new DOMParser().parseFromString(html, 'text/html');
        const styles = doc.querySelector('style')?.textContent || '';
        const bodyContent = doc.body.innerHTML;
        
        // Combine the styles and content
        setContent(`<style>${styles}</style>${bodyContent}`);
      })
      .catch(error => {
        console.error('Error loading help content:', error);
        setContent('Error loading help content. Please try again later.');
      });
  }, [stage]);

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-[1001] p-4">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-2xl max-h-[80vh] flex flex-col">
        <div className="flex items-center justify-between p-4 border-b border-gray-200">
          <h2 className="text-xl font-semibold text-gray-800">Help - Stage {stage}</h2>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
          >
            <X className="w-5 h-5" />
          </button>
        </div>
        
        <div 
          className="p-6 overflow-y-auto flex-grow prose prose-sm max-w-none"
          dangerouslySetInnerHTML={{ __html: content }}
        />
        
        <div className="p-4 border-t border-gray-200 flex justify-end">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default HelpModal;