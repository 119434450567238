import React, { useState } from 'react';
import { X } from 'lucide-react';
import { Rule } from '../types';

interface NewRuleModalProps {
  onClose: () => void;
  onSave: (rule: Rule) => void;
}

const NewRuleModal: React.FC<NewRuleModalProps> = ({ onClose, onSave }) => {
  const [rule, setRule] = useState<Rule>({
    id: '',
    'Allocate Based On': 'Account ID',
    'Allocation Value': '',
    'Override': null
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSave(rule);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[1000] p-4">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-2xl">
        <div className="flex justify-between items-center p-6 border-b border-gray-200">
          <h2 className="text-xl font-semibold text-gray-800">Add New Rule</h2>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
          >
            <X className="w-5 h-5" />
          </button>
        </div>
        
        <form onSubmit={handleSubmit} className="p-6 space-y-6">
          <div className="grid grid-cols-1 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Allocate Based On
              </label>
              <select
                value={rule['Allocate Based On']}
                onChange={(e) => setRule({ ...rule, 'Allocate Based On': e.target.value })}
                className="w-full px-4 py-2 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500"
              >
                <option value="Account ID">Account ID</option>
                <option value="Segment 2">Segment 2</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Allocation Value
              </label>
              <input
                type="text"
                value={rule['Allocation Value']}
                onChange={(e) => setRule({ ...rule, 'Allocation Value': e.target.value })}
                className="w-full px-4 py-2 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500"
                placeholder="Enter value"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Override Name (Optional)
              </label>
              <input
                type="text"
                value={rule['Override'] || ''}
                onChange={(e) => setRule({ ...rule, 'Override': e.target.value || null })}
                className="w-full px-4 py-2 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500"
                placeholder="Enter override name"
              />
            </div>
          </div>

          <div className="flex justify-end space-x-4 pt-4">
            <button
              type="button"
              onClick={onClose}
              className="px-6 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 font-medium"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 font-medium"
            >
              Add Rule
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewRuleModal;