// src/components/NewSplitRuleModal.tsx

import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { AccountGroup, SplitRule } from '../types';

interface DriverOption {
  value: string;
  label: string;
  hover_description: string;
}

interface NewSplitRuleModalProps {
  onClose: () => void;
  onSave: (rule: Omit<SplitRule, 'id'>) => void;
  accountGroups: AccountGroup[];
  preselectedGroup: string | null;
  driverOptions: DriverOption[];
}

const NewSplitRuleModal: React.FC<NewSplitRuleModalProps> = ({
  onClose,
  onSave,
  accountGroups,
  preselectedGroup,
  driverOptions,
}) => {
  const [rule, setRule] = useState<Omit<SplitRule, 'id'>>({
    accountGroupName: preselectedGroup || '',
    driver: driverOptions[0]?.value || '',
    newNamePrimary: '',
    newNameSecondary: '',
  });

  useEffect(() => {
    if (preselectedGroup) {
      setRule((prev) => ({
        ...prev,
        accountGroupName: preselectedGroup,
        newNamePrimary: `${preselectedGroup}-Primary`,
        newNameSecondary: `${preselectedGroup}-Secondary`,
      }));
    }
  }, [preselectedGroup, driverOptions]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSave(rule);
  };

  const selectedDriverOption = driverOptions.find(option => option.value === rule.driver);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[1000]">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-md">
        <div className="flex justify-between items-center p-4 border-b">
          <h3 className="text-lg font-semibold">Create New Split Rule</h3>
          <button onClick={onClose} className="p-1 hover:bg-gray-100 rounded-full">
            <X className="w-5 h-5" />
          </button>
        </div>
        <form onSubmit={handleSubmit} className="p-4 space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Account Group Name
            </label>
            <select
              value={rule.accountGroupName}
              onChange={(e) => setRule((prev) => ({ ...prev, accountGroupName: e.target.value }))}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              required
            >
              <option value="">Select Account Group</option>
              {accountGroups.map((group) => (
                <option key={group.name} value={group.name}>
                  {group.name} {group.friendlyName ? `(${group.friendlyName})` : ''}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Driver</label>
            <select
              value={rule.driver}
              onChange={(e) => setRule((prev) => ({ ...prev, driver: e.target.value }))}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 group"
              required
            >
              <option value="">Select Driver</option>
              {driverOptions.map((driver) => (
                <option 
                  key={driver.value} 
                  value={driver.value}
                  title={driver.hover_description}
                >
                  {driver.label}
                </option>
              ))}
            </select>
            {/* Hover description tooltip */}
            {selectedDriverOption && (
              <div className="mt-1 text-sm text-gray-500">
                {selectedDriverOption.hover_description}
              </div>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">New Name Primary</label>
            <input
              type="text"
              value={rule.newNamePrimary}
              onChange={(e) => setRule((prev) => ({ ...prev, newNamePrimary: e.target.value }))}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Enter primary group name"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">New Name Secondary</label>
            <input
              type="text"
              value={rule.newNameSecondary}
              onChange={(e) => setRule((prev) => ({ ...prev, newNameSecondary: e.target.value }))}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Enter secondary group name"
              required
            />
          </div>
          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
            >
              Create Rule
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewSplitRuleModal;