import React, { useState, useEffect, useRef, useCallback } from 'react';
import { ChevronLeft, Search, Trash2 } from 'lucide-react';
import { v4 as uuidv4 } from 'uuid';
import { Rule, Account, AccountGroup } from '../../types';
import { groupAccounts } from '../../utils/accountGrouping';
import NewRuleModal from '../NewRuleModal';
import AccountPreview from '../AccountPreview';

interface GroupAccountsProps {
  previewOpen: boolean;
  setPreviewOpen: (open: boolean) => void;
  rules: Rule[];
  setRules: (rules: Rule[]) => void;
  accounts: Account[];
  accountGroups: AccountGroup[];
}

const GroupAccounts: React.FC<GroupAccountsProps> = ({ 
  previewOpen, 
  setPreviewOpen,
  rules,
  setRules,
  accounts,
  accountGroups
}) => {
  const [selectedAccount, setSelectedAccount] = useState<number | null>(null);
  const [selectedRule, setSelectedRule] = useState<Rule | null>(null);
  const [groupingBy, setGroupingBy] = useState<'group' | 'account'>('group');
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [showNewRuleModal, setShowNewRuleModal] = useState(false);
  const rulesTableRef = useRef<HTMLDivElement>(null);
  const selectedRuleRef = useRef<HTMLTableRowElement>(null);

  // Scroll to selected rule
  useEffect(() => {
    if (selectedRuleRef.current && rulesTableRef.current) {
      selectedRuleRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  }, [selectedRule]);

  const addRule = (newRuleData: Omit<Rule, 'id'>) => {
    const newRule = {
      ...newRuleData,
      id: uuidv4()
    };
    setRules([...rules, newRule]);
    setShowNewRuleModal(false);
    setSelectedRule(newRule);
  };

  const deleteRule = (ruleToDelete: Rule) => {
    setRules(rules.filter(rule => rule.id !== ruleToDelete.id));
    if (selectedRule?.id === ruleToDelete.id) {
      setSelectedRule(null);
    }
  };

  const handleAccountClick = (accountId: number) => {
    if (selectedAccount === accountId) {
      setSelectedAccount(null);
      setSelectedRule(null);
    } else {
      setSelectedAccount(accountId);
      const account = accounts.find(a => a['Account ID'] === accountId);
      if (account) {
        const group = accountGroups.find(g => 
          g.accounts.some(a => a['Account ID'] === accountId)
        );
        if (group?.rule) {
          setSelectedRule(group.rule);
        } else {
          setSelectedRule(null);
        }
      }
    }
  };

  const handleRuleClick = (rule: Rule) => {
    if (selectedRule?.id === rule.id) {
      setSelectedRule(null);
      setSelectedAccount(null);
    } else {
      setSelectedRule(rule);
      setSelectedAccount(null);
      if (!previewOpen) {
        setPreviewOpen(true);
      }
    }
  };

  const handleClickOutside = (e: React.MouseEvent) => {
    if ((e.target as HTMLElement).closest('.rule-row') === null && 
        (e.target as HTMLElement).closest('.account-item') === null) {
      setSelectedAccount(null);
      setSelectedRule(null);
    }
  };

  const filteredRules = rules.filter(rule => {
    const searchLower = searchTerm.toLowerCase();
    return (
      rule['Allocate Based On'].toLowerCase().includes(searchLower) ||
      rule['Allocation Value'].toString().toLowerCase().includes(searchLower) ||
      (rule['Override'] || '').toString().toLowerCase().includes(searchLower)
    );
  });

  const getSortedRules = (rulesToSort: Rule[] = filteredRules) => {
    if (!sortField) return rulesToSort;
    return [...rulesToSort].sort((a, b) => {
      const aValue = a[sortField as keyof Rule];
      const bValue = b[sortField as keyof Rule];
      const direction = sortDirection === 'asc' ? 1 : -1;
      if (aValue === null && bValue === null) return 0;
      if (aValue === null) return direction;
      if (bValue === null) return -direction;
      return aValue < bValue ? -direction : direction;
    });
  };

  const sortedRules = getSortedRules();

  const handleSort = (field: string) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  return (
    <div className="flex h-full" onClick={handleClickOutside}>
      {/* Left Panel - Rules Configuration */}
      <div className="flex flex-col flex-grow p-6 transition-all duration-300">
        <div className="mb-6">
          <h2 className="text-xl font-semibold mb-2">Group Accounts Configuration</h2>
          <p className="text-gray-600">
            Configure rules to bundle accounts into groups. This reduces the number of individual rules needed later in the process.
          </p>
        </div>

        {/* Rules Table */}
        <div className="flex flex-col flex-grow bg-white rounded-lg shadow overflow-hidden" ref={rulesTableRef}>
          <div className="p-4 border-b border-gray-200">
            <div className="flex justify-between items-center mb-4">
              <div className="relative flex-1 max-w-md">
                <input
                  type="text"
                  placeholder="Search rules..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                />
                <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
              </div>
              <button
                onClick={() => setShowNewRuleModal(true)}
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
              >
                Add Rule
              </button>
            </div>
          </div>

          <div className="flex-grow overflow-auto">
            <table className="w-full">
              <thead className="bg-gray-50 sticky top-0">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Index
                  </th>
                  {['Allocate Based On', 'Allocation Value', 'Override'].map((header) => (
                    <th
                      key={header}
                      onClick={() => handleSort(header)}
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                    >
                      {header}
                      {sortField === header && (
                        <span className="ml-2">
                          {sortDirection === 'asc' ? '↑' : '↓'}
                        </span>
                      )}
                    </th>
                  ))}
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {sortedRules.map((rule, index) => (
                  <tr 
                    key={rule.id}
                    onClick={() => handleRuleClick(rule)}
                    ref={selectedRule?.id === rule.id ? selectedRuleRef : null}
                    className={`rule-row transition-colors duration-150 cursor-pointer ${
                      selectedRule?.id === rule.id ? 'bg-blue-50' : 'hover:bg-gray-50'
                    }`}
                  >
                    <td className="px-6 py-4 text-sm text-gray-500">
                      {index}
                    </td>
                    <td className="px-6 py-4">
                      <select
                        value={rule['Allocate Based On']}
                        onChange={(e) => {
                          setRules(prevRules => {
                            const newRules = [...prevRules];
                            const ruleIndex = newRules.findIndex(r => r.id === rule.id);
                            newRules[ruleIndex] = { ...rule, 'Allocate Based On': e.target.value };
                            return newRules;
                          });
                        }}
                        onClick={(e) => e.stopPropagation()}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                      >
                        <option value="Account ID">Account ID</option>
                        <option value="Segment 2">Segment 2</option>
                      </select>
                    </td>
                    <td className="px-6 py-4">
                      <input
                        type="text"
                        value={rule['Allocation Value']}
                        onChange={(e) => {
                          setRules(prevRules => {
                            const newRules = [...prevRules];
                            const ruleIndex = newRules.findIndex(r => r.id === rule.id);
                            newRules[ruleIndex] = { ...rule, 'Allocation Value': e.target.value };
                            return newRules;
                          });
                        }}
                        onClick={(e) => e.stopPropagation()}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                      />
                    </td>
                    <td className="px-6 py-4">
                      <input
                        type="text"
                        value={rule['Override'] || ''}
                        onChange={(e) => {
                          setRules(prevRules => {
                            const newRules = [...prevRules];
                            const ruleIndex = newRules.findIndex(r => r.id === rule.id);
                            newRules[ruleIndex] = { ...rule, 'Override': e.target.value || null };
                            return newRules;
                          });
                        }}
                        onClick={(e) => e.stopPropagation()}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        placeholder="Optional"
                      />
                    </td>
                    <td className="px-6 py-4">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteRule(rule);
                        }}
                        className="text-red-600 hover:text-red-900"
                      >
                        <Trash2 className="w-5 h-5" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Preview Toggle Button */}
      {!previewOpen && (
        <button
          onClick={() => setPreviewOpen(true)}
          className="fixed right-0 top-1/2 transform -translate-y-1/2 bg-white border border-gray-200 rounded-l-lg p-2 shadow-lg z-[999] flex flex-col items-center justify-center min-h-[80px] w-[40px]"
        >
          <ChevronLeft className="w-5 h-5" />
          <span className="writing-mode-vertical text-sm mt-2">Preview Results</span>
        </button>
      )}

      {/* Preview Panel */}
      {previewOpen && (
        <AccountPreview
          groups={accountGroups}
          groupingBy={groupingBy}
          onAccountClick={handleAccountClick}
          onGroupingChange={setGroupingBy}
          selectedAccount={selectedAccount}
          onClose={() => setPreviewOpen(false)}
          filteredByRule={selectedRule}
        />
      )}

      {/* New Rule Modal */}
      {showNewRuleModal && (
        <NewRuleModal
          onClose={() => setShowNewRuleModal(false)}
          onSave={addRule}
        />
      )}
    </div>
  );
};

export default GroupAccounts;