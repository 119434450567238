import { useState, useEffect } from 'react';

interface DriverOption {
  value: string;
  label: string;
  hover_description: string;
}

interface TestField {
  column_name: string;
  description: string;
  field_type: 'Freeform' | 'Dropdown';
  data_type: string;
  options: TestFieldOption[] | null;
}

interface TestFieldOption {
  value: string;
  label: string;
  info: string;
}

interface TestMethods {
  [key: string]: {
    fields: TestField[];
  };
}

interface S3Data {
  driverOptions: DriverOption[];
  testMethods: TestMethods;
  error: string | null;
  loading: boolean;
}

export const useS3Data = (): S3Data => {
  const [driverOptions, setDriverOptions] = useState<DriverOption[]>([]);
  const [testMethods, setTestMethods] = useState<TestMethods>({});
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://imagesslkincorta.s3.ap-southeast-2.amazonaws.com/hubert_config/accountData.json', {
          cache: 'no-store', // Prevents caching
          headers: {
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Pragma': 'no-cache',
            'Expires': '0'
          }
        });
        
        if (!response.ok) {
          throw new Error('Failed to fetch data from S3');
        }
        const data = await response.json();
        setDriverOptions(data["Stage 2 - Split Groups"]["Driver Options"] || []);
        setTestMethods(data["Stage 3 - Identify Reservations"]?.methods || {});
        setError(null);
      } catch (err) {
        console.error('Error fetching S3 data:', err);
        setError(err instanceof Error ? err.message : 'Failed to fetch data');
        setDriverOptions([]);
        setTestMethods({});
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { driverOptions, testMethods, error, loading };
};