import React, { useState } from 'react';
import { X, Search } from 'lucide-react';
import { Account } from '../types';

interface AccountPreviewModalProps {
  groupName: string;
  accounts: Account[];
  onClose: () => void;
  friendlyName?: string;
  originalGroupName?: string;
}

const AccountPreviewModal: React.FC<AccountPreviewModalProps> = ({
  groupName,
  accounts,
  onClose,
  friendlyName,
  originalGroupName
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredAccounts = accounts.filter(account => {
    const searchLower = searchTerm.toLowerCase();
    return (
      account['Account ID'].toString().toLowerCase().includes(searchLower) ||
      account['Account Name'].toLowerCase().includes(searchLower) ||
      account['Business Segment Name'].toLowerCase().includes(searchLower) ||
      account['Segment 2 ID'].toString().toLowerCase().includes(searchLower) ||
      account['Revenue Or Expense'].toLowerCase().includes(searchLower)
    );
  });

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[1001] p-4">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-4xl max-h-[80vh] flex flex-col">
        <div className="flex items-center justify-between p-4 border-b border-gray-200">
          <div>
            <h2 className="text-xl font-semibold text-gray-800">
              Accounts in Group: {groupName}
            </h2>
            {friendlyName && (
              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800 mt-1">
                {friendlyName}
              </span>
            )}
            {originalGroupName && (
              <div className="mt-1 text-sm text-gray-500">
                Split from: {originalGroupName}
              </div>
            )}
          </div>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="p-4 border-b border-gray-200">
          <div className="relative">
            <input
              type="text"
              placeholder="Search accounts by ID, name, segment..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          </div>
        </div>

        <div className="flex-1 overflow-auto p-4">
          <div className="grid gap-3">
            {filteredAccounts.map((account) => (
              <div
                key={account['Account ID']}
                className="bg-gray-50 rounded-lg p-4 hover:bg-gray-100 transition-colors"
              >
                <div className="flex justify-between items-start">
                  <div>
                    <div className="font-medium text-gray-900">
                      {account['Account Name']}
                    </div>
                    <div className="text-sm text-gray-500">
                      ID: {account['Account ID']} | Segment: {account['Segment 2 ID']} |{' '}
                      {account['Business Segment Name']}
                    </div>
                  </div>
                  <div
                    className={`text-sm px-2 py-1 rounded ${
                      account['Revenue Or Expense'] === 'Revenue'
                        ? 'bg-green-100 text-green-800'
                        : 'bg-blue-100 text-blue-800'
                    }`}
                  >
                    {account['Revenue Or Expense']}
                  </div>
                </div>
              </div>
            ))}

            {filteredAccounts.length === 0 && (
              <div className="text-center text-gray-500 py-8">
                No accounts match your search criteria
              </div>
            )}
          </div>
        </div>

        <div className="p-4 border-t border-gray-200 bg-gray-50">
          <div className="text-sm text-gray-600">
            Total Accounts: {accounts.length} |{' '}
            Showing: {filteredAccounts.length}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountPreviewModal;