import React, { useState } from 'react';
import { Search, ChevronRight, Plus, Trash2, Edit3, List } from 'lucide-react';
import { Account, AccountGroup, SplitGroup, SplitRule, FriendlyNameMapping } from '../types';
import EditFriendlyNameModal from './EditFriendlyNameModal';
import AccountPreviewModal from './AccountPreviewModal';
import { getFriendlyName } from '../utils/friendlyNames';

interface SplitGroupPreviewProps {
  accountGroups: AccountGroup[];
  splitGroups: SplitGroup[];
  selectedGroup: string | null;
  onGroupClick: (groupName: string) => void;
  onCreateRule: (groupName: string) => void;
  onDeleteRule: (ruleId: string) => void;
  onClose: () => void;
  filteredByRule: SplitRule | null;
  friendlyNames: FriendlyNameMapping[];
  onUpdateFriendlyName: (groupName: string, friendlyName: string) => void;
}

const SplitGroupPreview: React.FC<SplitGroupPreviewProps> = ({
  accountGroups,
  splitGroups,
  selectedGroup,
  onGroupClick,
  onCreateRule,
  onDeleteRule,
  onClose,
  filteredByRule,
  friendlyNames,
  onUpdateFriendlyName,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [hoveredGroup, setHoveredGroup] = useState<string | null>(null);
  const [editingGroup, setEditingGroup] = useState<string | null>(null);
  const [selectedAccountGroup, setSelectedAccountGroup] = useState<{
    name: string;
    accounts: Account[];
    friendlyName?: string;
    originalGroupName?: string;
  } | null>(null);

  const filterGroups = () => {
    const searchLower = searchTerm.toLowerCase();

    // Get all groups that haven't been split
    const unsplitGroups = accountGroups.filter(
      (group) => !splitGroups.some((splitGroup) => splitGroup.originalName === group.name)
    );

    let filteredUnsplit = unsplitGroups.filter(
      (group) =>
        group.name.toLowerCase().includes(searchLower) ||
        (getFriendlyName(group.name, friendlyNames)?.toLowerCase().includes(searchLower) ?? false)
    );

    let filteredSplit = splitGroups.filter(
      (splitGroup) =>
        splitGroup.originalName.toLowerCase().includes(searchLower) ||
        splitGroup.primaryGroup.name.toLowerCase().includes(searchLower) ||
        splitGroup.secondaryGroup.name.toLowerCase().includes(searchLower) ||
        (getFriendlyName(splitGroup.primaryGroup.name, friendlyNames)?.toLowerCase().includes(searchLower) ?? false) ||
        (getFriendlyName(splitGroup.secondaryGroup.name, friendlyNames)?.toLowerCase().includes(searchLower) ?? false)
    );

    if (filteredByRule) {
      filteredSplit = filteredSplit.filter((group) => group.rule.id === filteredByRule.id);
      filteredUnsplit = [];
    }

    return { filteredUnsplit, filteredSplit };
  };

  const { filteredUnsplit, filteredSplit } = filterGroups();

  const handleEditFriendlyName = (groupName: string) => {
    setEditingGroup(groupName);
  };

  const handleSaveFriendlyName = (friendlyName: string) => {
    if (editingGroup) {
      onUpdateFriendlyName(editingGroup, friendlyName);
      setEditingGroup(null);
    }
  };

  const handleViewAccounts = (groupName: string, originalGroupName?: string) => {
    // If this is a split group, find the original group's accounts
    const originalGroup = originalGroupName 
      ? accountGroups.find(g => g.name === originalGroupName)
      : accountGroups.find(g => g.name === groupName);

    if (originalGroup) {
      setSelectedAccountGroup({
        name: groupName,
        accounts: originalGroup.accounts,
        friendlyName: getFriendlyName(groupName, friendlyNames),
        originalGroupName: originalGroupName
      });
    }
  };

  const renderGroupName = (name: string) => (
    <div className="space-y-1">
      <div className="font-medium text-gray-900">{name}</div>
      {getFriendlyName(name, friendlyNames) && (
        <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
          {getFriendlyName(name, friendlyNames)}
        </div>
      )}
    </div>
  );

  return (
    <div className="fixed top-0 right-0 h-full bg-white border-l border-gray-200 shadow-lg w-[400px] flex flex-col">
      {/* Header */}
      <div className="p-4 border-b border-gray-200">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">
            Preview Results
            {filteredByRule && (
              <span className="ml-2 text-sm text-blue-600">(Filtered by rule)</span>
            )}
          </h2>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
          >
            <ChevronRight className="w-5 h-5" />
          </button>
        </div>
        <div className="relative">
          <input
            type="text"
            placeholder="Search groups..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
          <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
        </div>
      </div>

      {/* Groups List */}
      <div className="flex-1 overflow-auto p-4">
        {filteredSplit.map((splitGroup) => {
          // Find the original group to get its account count
          const originalGroup = accountGroups.find(g => g.name === splitGroup.originalName);
          const accountCount = originalGroup?.accounts.length || 0;
          
          return (
            <div
              key={splitGroup.id}
              className="border-b border-gray-200 group-item relative mb-4"
              onMouseEnter={() => setHoveredGroup(splitGroup.originalName)}
              onMouseLeave={() => setHoveredGroup(null)}
            >
              <div className="px-4 py-2 bg-gray-100 font-medium text-gray-700 flex justify-between items-center">
                <div className="flex-grow">
                  {renderGroupName(splitGroup.originalName)}
                </div>
                <div className="flex items-center space-x-2">
                  <button
                    onClick={() => handleViewAccounts(splitGroup.originalName)}
                    className="p-1 hover:bg-blue-100 rounded-full transition-colors"
                    title="View Accounts"
                  >
                    <List className="w-4 h-4 text-blue-600" />
                  </button>
                  <button
                    onClick={() => onDeleteRule(splitGroup.rule.id)}
                    className="p-1 hover:bg-red-100 rounded-full transition-colors"
                    title="Delete Rule"
                  >
                    <Trash2 className="w-4 h-4 text-red-600" />
                  </button>
                </div>
              </div>
              <div className="divide-y divide-gray-100 pl-4">
                {/* Primary Group */}
                <div
                  key={`${splitGroup.id}-primary`}
                  className={`px-4 py-3 cursor-pointer transition-colors duration-150 ${
                    selectedGroup === splitGroup.primaryGroup.name ? 'bg-blue-50' : 'hover:bg-gray-50'
                  } flex justify-between items-center`}
                >
                  <div
                    onClick={() => onGroupClick(splitGroup.primaryGroup.name)}
                    className="flex-grow"
                  >
                    {renderGroupName(splitGroup.primaryGroup.name)}
                    <div className="text-sm text-gray-500 mt-1">
                      {accountCount} accounts
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleViewAccounts(splitGroup.primaryGroup.name, splitGroup.originalName);
                      }}
                      className="p-1 hover:bg-blue-100 rounded-full transition-colors"
                      title="View Accounts"
                    >
                      <List className="w-4 h-4 text-blue-600" />
                    </button>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEditFriendlyName(splitGroup.primaryGroup.name);
                      }}
                      className="p-1 hover:bg-blue-100 rounded-full transition-colors"
                      title="Edit friendly name"
                    >
                      <Edit3 className="w-4 h-4 text-blue-600" />
                    </button>
                  </div>
                </div>
                {/* Secondary Group */}
                <div
                  key={`${splitGroup.id}-secondary`}
                  className={`px-4 py-3 cursor-pointer transition-colors duration-150 ${
                    selectedGroup === splitGroup.secondaryGroup.name ? 'bg-blue-50' : 'hover:bg-gray-50'
                  } flex justify-between items-center`}
                >
                  <div
                    onClick={() => onGroupClick(splitGroup.secondaryGroup.name)}
                    className="flex-grow"
                  >
                    {renderGroupName(splitGroup.secondaryGroup.name)}
                    <div className="text-sm text-gray-500 mt-1">
                      {accountCount} accounts
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleViewAccounts(splitGroup.secondaryGroup.name, splitGroup.originalName);
                      }}
                      className="p-1 hover:bg-blue-100 rounded-full transition-colors"
                      title="View Accounts"
                    >
                      <List className="w-4 h-4 text-blue-600" />
                    </button>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEditFriendlyName(splitGroup.secondaryGroup.name);
                      }}
                      className="p-1 hover:bg-blue-100 rounded-full transition-colors"
                      title="Edit friendly name"
                    >
                      <Edit3 className="w-4 h-4 text-blue-600" />
                    </button>
                  </div>
                </div>
              </div>

              {/* Hover Tooltip */}
              {hoveredGroup === splitGroup.originalName && (
                <div
                  className="absolute z-[1000] bg-white p-4 rounded-lg shadow-lg border border-gray-200 w-80"
                  style={{
                    right: '420px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                  }}
                >
                  <div className="space-y-3">
                    <h3 className="font-semibold text-gray-900">Split Rule Information</h3>
                    <div className="text-gray-700 bg-blue-50 p-2 rounded">
                      <div>Original Group: {splitGroup.originalName}</div>
                      <div>Driver: {splitGroup.rule.driver}</div>
                      <div>Primary Name: {splitGroup.rule.newNamePrimary}</div>
                      <div>Secondary Name: {splitGroup.rule.newNameSecondary}</div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          );
        })}

        {filteredUnsplit.map((group) => (
          <div
            key={group.id}
            className="relative group group-item mb-4"
            onMouseEnter={() => setHoveredGroup(group.name)}
            onMouseLeave={() => setHoveredGroup(null)}
          >
            <div
              className={`px-4 py-3 cursor-pointer transition-colors duration-150 ${
                selectedGroup === group.name ? 'bg-blue-50' : 'hover:bg-gray-50'
              } flex justify-between items-center`}
              onClick={() => onGroupClick(group.name)}
            >
              <div className="flex-grow">
                {renderGroupName(group.name)}
                <div className="text-sm text-gray-500 mt-1">
                  {group.accounts.length} accounts
                </div>
              </div>
              <div className="flex items-center space-x-2">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleViewAccounts(group.name);
                  }}
                  className="p-1 hover:bg-blue-100 rounded-full transition-colors"
                  title="View Accounts"
                >
                  <List className="w-4 h-4 text-blue-600" />
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEditFriendlyName(group.name);
                  }}
                  className="p-1 hover:bg-blue-100 rounded-full transition-colors"
                  title="Edit friendly name"
                >
                  <Edit3 className="w-4 h-4 text-blue-600" />
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    onCreateRule(group.name);
                  }}
                  className="p-1 hover:bg-blue-100 rounded-full transition-colors"
                  title="Create Split Rule"
                >
                  <Plus className="w-5 h-5 text-blue-600" />
                </button>
              </div>
            </div>
          </div>
        ))}

        {filteredSplit.length === 0 && filteredUnsplit.length === 0 && (
          <div className="text-center text-gray-500">No account groups to display.</div>
        )}
      </div>

      {/* Edit Friendly Name Modal */}
      {editingGroup && (
        <EditFriendlyNameModal
          groupName={editingGroup}
          currentFriendlyName={getFriendlyName(editingGroup, friendlyNames) || ''}
          onSave={handleSaveFriendlyName}
          onClose={() => setEditingGroup(null)}
        />
      )}

      {/* Account Preview Modal */}
      {selectedAccountGroup && (
        <AccountPreviewModal
          groupName={selectedAccountGroup.name}
          accounts={selectedAccountGroup.accounts}
          friendlyName={selectedAccountGroup.friendlyName}
          originalGroupName={selectedAccountGroup.originalGroupName}
          onClose={() => setSelectedAccountGroup(null)}
        />
      )}
    </div>
  );
};

export default SplitGroupPreview;