import React, { useState, useEffect } from 'react';
import { X, AlertCircle } from 'lucide-react';
import { IdentificationRule, TestField } from '../types';

interface NewIdentificationRuleModalProps {
  onClose: () => void;
  onSave: (rule: Omit<IdentificationRule, 'id'>) => void;
  accountGroups: string[];
  methods: {
    [key: string]: {
      fields: TestField[];
    };
  };
  preselectedGroup?: string | null;
}

const NewIdentificationRuleModal: React.FC<NewIdentificationRuleModalProps> = ({
  onClose,
  onSave,
  accountGroups,
  methods,
  preselectedGroup
}) => {
  const [rule, setRule] = useState<Omit<IdentificationRule, 'id'>>({
    'Account Group Name': preselectedGroup || accountGroups[0] || '',
    'Test_Table': 'Sales Batch Method',
    'Detection Indicator': 'Revenue',
    'Test Column': methods['Sales Batch Method'].fields[0].column_name,
    'Test Value': ''
  });

  useEffect(() => {
    if (preselectedGroup) {
      setRule(prev => ({
        ...prev,
        'Account Group Name': preselectedGroup
      }));
    }
  }, [preselectedGroup]);

  const [error, setError] = useState<string | null>(null);
  const [selectedField, setSelectedField] = useState<TestField>(methods['Sales Batch Method'].fields[0]);

  useEffect(() => {
    const field = methods[rule.Test_Table].fields.find(f => f.column_name === rule.Test_Column);
    if (field) {
      setSelectedField(field);
    }
  }, [rule.Test_Table, rule.Test_Column, methods]);

  const validateTestValue = (value: string): boolean => {
    if (selectedField.data_type === 'String') return true;
    if (selectedField.data_type === 'Number') {
      return !isNaN(Number(value));
    }
    return true;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateTestValue(rule.Test_Value)) {
      setError(`Invalid value for ${selectedField.column_name}. Expected ${selectedField.data_type}.`);
      return;
    }

    onSave(rule);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[1000] p-4">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-2xl">
        <div className="flex justify-between items-center p-6 border-b border-gray-200">
          <h2 className="text-xl font-semibold text-gray-800">Add New Identification Rule</h2>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
          >
            <X className="w-5 h-5" />
          </button>
        </div>
        
        <form onSubmit={handleSubmit} className="p-6 space-y-6">
          {error && (
            <div className="p-3 bg-red-100 border border-red-200 rounded-md flex items-center text-red-700">
              <AlertCircle className="w-5 h-5 mr-2" />
              {error}
            </div>
          )}

          <div className="grid grid-cols-1 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Account Group
              </label>
              <select
                value={rule['Account Group Name']}
                onChange={(e) => setRule(prev => ({ ...prev, 'Account Group Name': e.target.value }))}
                className="w-full px-4 py-2 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500"
                required
              >
                {accountGroups.map(group => (
                  <option key={group} value={group}>
                    {group}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Test Table
              </label>
              <select
                value={rule.Test_Table}
                onChange={(e) => {
                  const newTestTable = e.target.value as 'Sales Batch Method' | 'QT Data Method';
                  setRule(prev => ({
                    ...prev,
                    Test_Table: newTestTable,
                    Test_Column: methods[newTestTable].fields[0].column_name,
                    Test_Value: ''
                  }));
                }}
                className="w-full px-4 py-2 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500"
                required
              >
                <option value="Sales Batch Method">Sales Batch Method</option>
                <option value="QT Data Method">QT Data Method</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Detection Indicator
              </label>
              <select
                value={rule.Detection_Indicator}
                onChange={(e) => setRule(prev => ({ 
                  ...prev, 
                  Detection_Indicator: e.target.value as 'Revenue' | 'Vendor Cost' | 'Commission'
                }))}
                className="w-full px-4 py-2 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500"
                required
              >
                <option value="Revenue">Revenue</option>
                <option value="Vendor Cost">Vendor Cost</option>
                <option value="Commission">Commission</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Test Column
              </label>
              <select
                value={rule.Test_Column}
                onChange={(e) => {
                  const field = methods[rule.Test_Table].fields.find(f => f.column_name === e.target.value);
                  setRule(prev => ({ ...prev, Test_Column: e.target.value, Test_Value: '' }));
                  if (field) {
                    setSelectedField(field);
                  }
                }}
                className="w-full px-4 py-2 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500"
                required
              >
                {methods[rule.Test_Table].fields.map(field => (
                  <option 
                    key={field.column_name} 
                    value={field.column_name} 
                    title={field.description}
                  >
                    {field.column_name}
                  </option>
                ))}
              </select>
              {/* Show the description below the dropdown */}
              {selectedField && (
                <p className="mt-1 text-sm text-gray-500">{selectedField.description}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Test Value
              </label>
              {selectedField.field_type === 'Dropdown' && selectedField.options ? (
                <select
                  value={rule.Test_Value}
                  onChange={(e) => setRule(prev => ({ ...prev, Test_Value: e.target.value }))}
                  className="w-full px-4 py-2 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500"
                  required
                >
                  <option value="">Select a value</option>
                  {selectedField.options.map(option => (
                    <option key={option.value} value={option.value} title={option.info}>
                      {option.label}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  type="text"
                  value={rule.Test_Value}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setRule(prev => ({ ...prev, Test_Value: newValue }));
                    if (!validateTestValue(newValue)) {
                      setError(`Invalid value for ${selectedField.column_name}. Expected ${selectedField.data_type}.`);
                    } else {
                      setError(null);
                    }
                  }}
                  className="w-full px-4 py-2 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500"
                  placeholder={`Enter ${selectedField.data_type.toLowerCase()} value`}
                  required
                />
              )}
            </div>
          </div>

          <div className="flex justify-end space-x-4 pt-4">
            <button
              type="button"
              onClick={onClose}
              className="px-6 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 font-medium"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 font-medium"
              disabled={!!error}
            >
              Add Rule
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewIdentificationRuleModal;