import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { ChevronLeft, Search, Trash2, AlertCircle, X } from 'lucide-react';
import { v4 as uuidv4 } from 'uuid';
import { AllocationRule, AccountGroup, SplitRule, FriendlyNameMapping } from '../../types';
import accountData from '../../data/accountData.json';
import NewAllocationRuleModal from '../NewAllocationRuleModal';
import SummaryPane from '../SummaryPane';
import { getFinalGroups } from '../../utils/getFinalGroups';
import { getFriendlyName } from '../../utils/friendlyNames';

interface AllocateMoniesProps {
  previewOpen: boolean;
  setPreviewOpen: (open: boolean) => void;
  rules: AllocationRule[];
  setRules: (rules: AllocationRule[]) => void;
  accountGroups: AccountGroup[];
  splitRules: SplitRule[];
  friendlyNames: FriendlyNameMapping[];
}

const AllocateMonies: React.FC<AllocateMoniesProps> = ({
  previewOpen,
  setPreviewOpen,
  rules,
  setRules,
  accountGroups,
  splitRules,
  friendlyNames
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showNewRuleModal, setShowNewRuleModal] = useState(false);
  const [selectedRule, setSelectedRule] = useState<AllocationRule | null>(null);
  const [selectedGroup, setSelectedGroup] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [preselectedGroup, setPreselectedGroup] = useState<string | null>(null);
  const [existingRule, setExistingRule] = useState<AllocationRule | null>(null);

  const driverOptions = useMemo(() => {
    return accountData["Stage 4 - Allocate Monies"]["Part 4 Driver Options"] || [];
  }, []);

  // Get final groups after splits
  const finalGroups = useMemo(() => 
    getFinalGroups(accountGroups, splitRules),
    [accountGroups, splitRules]
  );

  // Create a list of groups with their friendly names
  const groupsWithFriendlyNames = useMemo(() => 
    finalGroups.map(group => ({
      name: group.name,
      friendlyName: getFriendlyName(group.name, friendlyNames)
    })),
    [finalGroups, friendlyNames]
  );

  // Group rules by account group
  const rulesByGroup = useMemo(() => {
    const grouped = new Map<string, AllocationRule[]>();
    rules.forEach(rule => {
      const group = rule['Account Group Name'];
      if (!grouped.has(group)) {
        grouped.set(group, []);
      }
      grouped.get(group)!.push(rule);
    });
    return grouped;
  }, [rules]);

  // Split groups into configured and unconfigured
  const { configuredGroups, unconfiguredGroups } = useMemo(() => {
    const configured: { name: string; ruleCount: number; friendlyName?: string }[] = [];
    const unconfigured: string[] = [];

    finalGroups.forEach(group => {
      const groupRules = rulesByGroup.get(group.name);
      const friendlyName = getFriendlyName(group.name, friendlyNames);
      
      if (groupRules && groupRules.length > 0) {
        configured.push({
          name: group.name,
          ruleCount: groupRules.length,
          friendlyName
        });
      } else {
        unconfigured.push(group.name);
      }
    });

    return {
      configuredGroups: configured.sort((a, b) => a.name.localeCompare(b.name)),
      unconfiguredGroups: unconfigured.sort()
    };
  }, [finalGroups, rulesByGroup, friendlyNames]);

  const checkExistingRule = useCallback((groupName: string) => {
    return rules.find(rule => rule['Account Group Name'] === groupName) || null;
  }, [rules]);

  const handleAddRuleAttempt = useCallback((groupName: string) => {
    const existing = checkExistingRule(groupName);
    if (existing) {
      setExistingRule(existing);
    } else {
      setPreselectedGroup(groupName);
      setShowNewRuleModal(true);
    }
  }, [checkExistingRule]);

  const addRule = (newRuleData: Omit<AllocationRule, 'id'>) => {
    try {
      const existing = checkExistingRule(newRuleData['Account Group Name']);
      if (existing) {
        setExistingRule(existing);
        return;
      }

      const newRule = {
        ...newRuleData,
        id: uuidv4()
      };
      setRules(prevRules => [...prevRules, newRule]);
      setShowNewRuleModal(false);
      setSelectedRule(newRule);
      setPreselectedGroup(null);
      setError(null);
    } catch (err) {
      setError('Failed to add new rule');
    }
  };

  const deleteRule = (ruleId: string) => {
    try {
      setRules(prevRules => prevRules.filter(rule => rule.id !== ruleId));
      if (selectedRule?.id === ruleId) {
        setSelectedRule(null);
      }
      setError(null);
    } catch (err) {
      setError('Failed to delete rule');
    }
  };

  const handleDeleteExistingRule = () => {
    if (existingRule) {
      deleteRule(existingRule.id);
      setExistingRule(null);
      setPreselectedGroup(existingRule['Account Group Name']);
      setShowNewRuleModal(true);
    }
  };

  const updateRule = (ruleId: string, updates: Partial<AllocationRule>) => {
    try {
      if (updates['Account Group Name']) {
        const existing = checkExistingRule(updates['Account Group Name']);
        if (existing && existing.id !== ruleId) {
          setError(`Group "${updates['Account Group Name']}" already has a rule configured`);
          return;
        }
      }

      setRules(prevRules => 
        prevRules.map(rule => 
          rule.id === ruleId ? { ...rule, ...updates } : rule
        )
      );
      setError(null);
    } catch (err) {
      setError('Failed to update rule');
    }
  };

  const handleGroupClick = (groupName: string | null) => {
    setSelectedGroup(groupName);
    setSearchTerm(groupName || '');
  };

  const handleAddRule = (groupName: string) => {
    handleAddRuleAttempt(groupName);
  };

  const filteredRules = useMemo(() => {
    if (!searchTerm) return rules;

    const searchLower = searchTerm.toLowerCase();
    return rules.filter(rule => {
      if (selectedGroup && rule['Account Group Name'] !== selectedGroup) {
        return false;
      }

      const searchableFields = {
        accountGroup: String(rule['Account Group Name'] || '').toLowerCase(),
        driver: String(rule.Driver || '').toLowerCase(),
        destination: rule.Destination ? String(rule.Destination).toLowerCase() : '',
        friendlyName: getFriendlyName(rule['Account Group Name'], friendlyNames)?.toLowerCase() || ''
      };

      return Object.values(searchableFields).some(value => value.includes(searchLower));
    });
  }, [rules, searchTerm, selectedGroup, friendlyNames]);

  return (
    <div className="flex h-full">
      <div className="flex flex-col flex-grow p-6 transition-all duration-300">
        <div className="mb-6">
          <h2 className="text-xl font-semibold mb-2">Allocate Monies</h2>
          <p className="text-gray-600">
            Configure rules to determine how monies should be allocated between reservations.
          </p>
        </div>

        {error && (
          <div className="mb-4 p-3 bg-red-100 border border-red-200 rounded-md flex items-center text-red-700">
            <AlertCircle className="w-5 h-5 mr-2" />
            {error}
          </div>
        )}

        <div className="flex flex-col flex-grow bg-white rounded-lg shadow">
          <div className="p-4 border-b border-gray-200">
            <div className="flex justify-between items-center mb-4">
              <div className="relative flex-1 max-w-md">
                <input
                  type="text"
                  placeholder="Search rules..."
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    if (!e.target.value) {
                      setSelectedGroup(null);
                    }
                  }}
                  className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                />
                <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                {selectedGroup && (
                  <button
                    onClick={() => {
                      setSelectedGroup(null);
                      setSearchTerm('');
                    }}
                    className="absolute right-2 top-2 p-1 hover:bg-gray-100 rounded-full"
                  >
                    <X className="w-4 h-4 text-gray-500" />
                  </button>
                )}
              </div>
              <button
                onClick={() => setShowNewRuleModal(true)}
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
              >
                Add Rule
              </button>
            </div>
          </div>

          <div className="overflow-auto flex-grow">
            <table className="w-full">
              <thead className="bg-gray-50 sticky top-0">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Account Group
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Driver
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Destination
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredRules.map((rule) => (
                  <tr
                    key={rule.id}
                    onClick={() => setSelectedRule(selectedRule?.id === rule.id ? null : rule)}
                    className={`transition-colors duration-150 cursor-pointer ${
                      selectedRule?.id === rule.id ? 'bg-blue-50' : 'hover:bg-gray-50'
                    }`}
                  >
                    <td className="px-6 py-4">
                      <select
                        value={rule['Account Group Name']}
                        onChange={(e) => updateRule(rule.id, { 'Account Group Name': e.target.value })}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                      >
                        <option value="">Select Account Group</option>
                        {finalGroups.map(group => (
                          <option key={group.name} value={group.name}>
                            {group.name} {getFriendlyName(group.name, friendlyNames) ? 
                              `(${getFriendlyName(group.name, friendlyNames)})` : ''}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td className="px-6 py-4">
                      <select
                        value={rule.Driver}
                        onChange={(e) => {
                          const newDriver = e.target.value;
                          updateRule(rule.id, { 
                            Driver: newDriver,
                            Destination: newDriver === 'sent_to' ? rule.Destination : null
                          });
                        }}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        title={driverOptions.find(d => d.value === rule.Driver)?.hover_description}
                      >
                        <option value="">Select Driver</option>
                        {driverOptions.map(driver => (
                          <option 
                            key={driver.value} 
                            value={driver.value}
                            title={driver.hover_description}
                          >
                            {driver.label}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td className="px-6 py-4">
                      {rule.Driver === 'sent_to' && (
                        <input
                          type="text"
                          value={rule.Destination || ''}
                          onChange={(e) => updateRule(rule.id, { 
                            Destination: e.target.value || null 
                          })}
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                          placeholder="Enter destination"
                        />
                      )}
                    </td>
                    <td className="px-6 py-4">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteRule(rule.id);
                        }}
                        className="text-red-600 hover:text-red-900"
                      >
                        <Trash2 className="w-5 h-5" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {!previewOpen && (
        <button
          onClick={() => setPreviewOpen(true)}
          className="fixed right-0 top-1/2 transform -translate-y-1/2 bg-white border border-gray-200 rounded-l-lg p-2 shadow-lg z-[999] flex flex-col items-center justify-center min-h-[80px] w-[40px]"
        >
          <ChevronLeft className="w-5 h-5" />
          <span className="writing-mode-vertical text-sm mt-2">Summary</span>
        </button>
      )}

      {previewOpen && (
        <SummaryPane
          configuredGroups={configuredGroups}
          unconfiguredGroups={unconfiguredGroups}
          onGroupClick={handleGroupClick}
          onClose={() => setPreviewOpen(false)}
          selectedGroup={selectedGroup}
          onAddRule={handleAddRule}
          accountGroups={groupsWithFriendlyNames}
        />
      )}

      {showNewRuleModal && (
        <NewAllocationRuleModal
          onClose={() => {
            setShowNewRuleModal(false);
            setPreselectedGroup(null);
          }}
          onSave={addRule}
          accountGroups={finalGroups}
          driverOptions={driverOptions}
          preselectedGroup={preselectedGroup}
        />
      )}

      {existingRule && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[1000]">
          <div className="bg-white rounded-lg shadow-xl w-full max-w-md p-6">
            <h3 className="text-lg font-semibold mb-4">Existing Rule Found</h3>
            <p className="text-gray-600 mb-4">
              This group already has a rule configured:
            </p>
            <div className="bg-gray-50 p-4 rounded-md mb-6">
              <div className="grid grid-cols-2 gap-2 text-sm">
                <div className="text-gray-500">Driver:</div>
                <div className="font-medium">
                  {driverOptions.find(d => d.value === existingRule.Driver)?.label}
                </div>
                {existingRule.Driver === 'sent_to' && (
                  <>
                    <div className="text-gray-500">Destination:</div>
                    <div className="font-medium">{existingRule.Destination}</div>
                  </>
                )}
              </div>
            </div>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setExistingRule(null)}
                className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteExistingRule}
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
              >
                Delete Existing & Create New
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllocateMonies;