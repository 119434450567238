import React, { useState } from 'react';
import { Search, ChevronRight, Plus } from 'lucide-react';

interface GroupInfo {
  name: string;
  ruleCount: number;
  friendlyName?: string;
}

interface SummaryPaneProps {
  configuredGroups: GroupInfo[];
  unconfiguredGroups: string[];
  onGroupClick: (groupName: string | null) => void;
  onClose: () => void;
  selectedGroup: string | null;
  onAddRule: (groupName: string) => void;
  accountGroups: { name: string; friendlyName?: string }[];
}

const SummaryPane: React.FC<SummaryPaneProps> = ({
  configuredGroups,
  unconfiguredGroups,
  onGroupClick,
  onClose,
  selectedGroup,
  onAddRule,
  accountGroups
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filterGroups = (groups: any[]) => {
    if (!searchTerm) return groups;
    const searchLower = searchTerm.toLowerCase();
    return groups.filter(group => {
      const name = typeof group === 'string' ? group : group.name;
      const friendlyName = typeof group === 'string' 
        ? accountGroups.find(g => g.name === group)?.friendlyName 
        : group.friendlyName;
      return name.toLowerCase().includes(searchLower) || 
             (friendlyName && friendlyName.toLowerCase().includes(searchLower));
    });
  };

  const handleGroupClick = (groupName: string) => {
    if (selectedGroup === groupName) {
      onGroupClick(null); // Deselect if clicking the same group
    } else {
      onGroupClick(groupName);
    }
  };

  const filteredConfigured = filterGroups(configuredGroups);
  const filteredUnconfigured = filterGroups(unconfiguredGroups);

  const renderGroupName = (name: string, friendlyName?: string) => {
    // If no friendlyName is provided directly, try to find it from accountGroups
    const resolvedFriendlyName = friendlyName || accountGroups.find(g => g.name === name)?.friendlyName;
    
    return (
      <div>
        <div className="font-medium text-gray-900">{name}</div>
        {resolvedFriendlyName && (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800 mt-1">
            {resolvedFriendlyName}
          </span>
        )}
      </div>
    );
  };

  return (
    <div className="fixed top-0 right-0 h-full bg-white border-l border-gray-200 shadow-lg w-[400px] flex flex-col">
      {/* Header */}
      <div className="p-4 border-b border-gray-200">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">Summary</h2>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
          >
            <ChevronRight className="w-5 h-5" />
          </button>
        </div>
        <div className="relative">
          <input
            type="text"
            placeholder="Search groups..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
          <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
        </div>
      </div>

      {/* Groups List */}
      <div className="flex-1 overflow-auto">
        {/* Unconfigured Groups Section */}
        {filteredUnconfigured.length > 0 && (
          <div className="border-b border-gray-200">
            <div className="px-4 py-2 bg-gray-50">
              <h3 className="text-sm font-medium text-gray-700">
                Unconfigured Groups ({filteredUnconfigured.length})
              </h3>
            </div>
            <div className="divide-y divide-gray-100">
              {filteredUnconfigured.map((group) => (
                <div
                  key={group}
                  className={`px-4 py-3 cursor-pointer transition-colors duration-150 ${
                    selectedGroup === group ? 'bg-blue-50' : 'hover:bg-gray-50'
                  }`}
                >
                  <div className="flex justify-between items-center">
                    <div 
                      onClick={() => handleGroupClick(group)}
                      className="flex-grow"
                    >
                      {renderGroupName(group)}
                      <div className="text-sm text-red-600">No rules configured</div>
                    </div>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        onAddRule(group);
                      }}
                      className="ml-2 p-1 hover:bg-blue-100 rounded-full transition-colors"
                      title="Add rule"
                    >
                      <Plus className="w-5 h-5 text-blue-600" />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Configured Groups Section */}
        {filteredConfigured.length > 0 && (
          <div>
            <div className="px-4 py-2 bg-gray-50">
              <h3 className="text-sm font-medium text-gray-700">
                Configured Groups ({filteredConfigured.length})
              </h3>
            </div>
            <div className="divide-y divide-gray-100">
              {filteredConfigured.map((group) => (
                <div
                  key={group.name}
                  className={`px-4 py-3 cursor-pointer transition-colors duration-150 ${
                    selectedGroup === group.name ? 'bg-blue-50' : 'hover:bg-gray-50'
                  }`}
                >
                  <div className="flex justify-between items-center">
                    <div 
                      onClick={() => handleGroupClick(group.name)}
                      className="flex-grow"
                    >
                      {renderGroupName(group.name, group.friendlyName)}
                      <div className="flex items-center mt-1">
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                          {group.ruleCount} {group.ruleCount === 1 ? 'rule' : 'rules'}
                        </span>
                      </div>
                    </div>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        onAddRule(group.name);
                      }}
                      className="ml-2 p-1 hover:bg-blue-100 rounded-full transition-colors"
                      title="Add rule"
                    >
                      <Plus className="w-5 h-5 text-blue-600" />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {filteredConfigured.length === 0 && filteredUnconfigured.length === 0 && (
          <div className="p-4 text-center text-gray-500">
            No groups match your search
          </div>
        )}
      </div>
    </div>
  );
};

export default SummaryPane;