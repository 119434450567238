import { AccountGroup, SplitRule } from '../types';

export const getFinalGroups = (accountGroups: AccountGroup[], splitRules: SplitRule[]): AccountGroup[] => {
  const finalGroups: AccountGroup[] = [];

  accountGroups.forEach(group => {
    const splitRule = splitRules.find(rule => rule.accountGroupName === group.name);
    
    if (splitRule) {
      // If group was split, add both new groups with the full account list
      // Primary group
      finalGroups.push({
        id: `${group.id}-primary`,
        name: splitRule.newNamePrimary,
        accounts: group.accounts,  // Use full account list
        rule: group.rule,
        sortOrder: group.sortOrder
      });

      // Secondary group
      finalGroups.push({
        id: `${group.id}-secondary`,
        name: splitRule.newNameSecondary,
        accounts: group.accounts,  // Use full account list
        rule: group.rule,
        sortOrder: group.sortOrder
      });
    } else {
      // If group wasn't split, add it as is
      finalGroups.push(group);
    }
  });

  return finalGroups.sort((a, b) => a.name.localeCompare(b.name));
};