import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { ChevronLeft, Search, Trash2, AlertCircle, X } from 'lucide-react';
import { v4 as uuidv4 } from 'uuid';
import { IdentificationRule, AccountGroup, TestField, FriendlyNameMapping } from '../../types';
import NewIdentificationRuleModal from '../NewIdentificationRuleModal';
import SummaryPane from '../SummaryPane';
import { useS3Data } from '../../hooks/useS3Data';
import { getFinalGroups } from '../../utils/getFinalGroups';
import { getFriendlyName } from '../../utils/friendlyNames';

interface IdentifyReservationsProps {
  previewOpen: boolean;
  setPreviewOpen: (open: boolean) => void;
  rules: IdentificationRule[];
  setRules: (rules: IdentificationRule[]) => void;
  accountGroups: AccountGroup[];
  splitRules: SplitRule[];
  friendlyNames: FriendlyNameMapping[];
}

const IdentifyReservations: React.FC<IdentifyReservationsProps> = ({
  previewOpen,
  setPreviewOpen,
  rules,
  setRules,
  accountGroups,
  splitRules,
  friendlyNames
}) => {
  const { testMethods, error: s3Error, loading } = useS3Data();
  const [searchTerm, setSearchTerm] = useState('');
  const [showNewRuleModal, setShowNewRuleModal] = useState(false);
  const [selectedRule, setSelectedRule] = useState<IdentificationRule | null>(null);
  const [selectedGroup, setSelectedGroup] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [preselectedGroup, setPreselectedGroup] = useState<string | null>(null);

  const finalGroups = useMemo(() => 
    getFinalGroups(accountGroups, splitRules),
    [accountGroups, splitRules]
  );

  // Create a list of groups with their friendly names for the summary pane
  const groupsWithFriendlyNames = useMemo(() => 
    finalGroups.map(group => ({
      name: group.name,
      friendlyName: getFriendlyName(group.name, friendlyNames)
    })),
    [finalGroups, friendlyNames]
  );

  const getFieldDescription = useCallback((testTable: string, columnName: string): string => {
    const field = testMethods[testTable]?.fields.find(f => f.column_name === columnName);
    return field?.description || '';
  }, [testMethods]);

  const rulesByGroup = useMemo(() => {
    const grouped = new Map<string, IdentificationRule[]>();
    rules.forEach(rule => {
      const group = rule['Account Group Name'];
      if (!grouped.has(group)) {
        grouped.set(group, []);
      }
      grouped.get(group)!.push(rule);
    });
    return grouped;
  }, [rules]);

  const { configuredGroups, unconfiguredGroups } = useMemo(() => {
    const configured: { name: string; ruleCount: number; friendlyName?: string }[] = [];
    const unconfigured: string[] = [];

    finalGroups.forEach(group => {
      const groupRules = rulesByGroup.get(group.name);
      const friendlyName = getFriendlyName(group.name, friendlyNames);
      
      if (groupRules && groupRules.length > 0) {
        configured.push({
          name: group.name,
          ruleCount: groupRules.length,
          friendlyName
        });
      } else {
        unconfigured.push(group.name);
      }
    });

    return {
      configuredGroups: configured.sort((a, b) => a.name.localeCompare(b.name)),
      unconfiguredGroups: unconfigured.sort()
    };
  }, [finalGroups, rulesByGroup, friendlyNames]);

  const addRule = (newRuleData: Omit<IdentificationRule, 'id'>) => {
    try {
      const newRule = {
        ...newRuleData,
        id: uuidv4()
      };
      setRules(prevRules => [...prevRules, newRule]);
      setShowNewRuleModal(false);
      setSelectedRule(newRule);
      setPreselectedGroup(null);
      setError(null);
    } catch (err) {
      setError('Failed to add new rule');
    }
  };

  const deleteRule = (ruleId: string) => {
    try {
      setRules(prevRules => prevRules.filter(rule => rule.id !== ruleId));
      if (selectedRule?.id === ruleId) {
        setSelectedRule(null);
      }
      setError(null);
    } catch (err) {
      setError('Failed to delete rule');
    }
  };

  const updateRule = (ruleId: string, updates: Partial<IdentificationRule>) => {
    try {
      setRules(prevRules => 
        prevRules.map(rule => 
          rule.id === ruleId ? { ...rule, ...updates } : rule
        )
      );
      setError(null);
    } catch (err) {
      setError('Failed to update rule');
    }
  };

  const handleGroupClick = (groupName: string | null) => {
    setSelectedGroup(groupName);
    setSearchTerm(groupName || '');
  };

  const handleAddRule = (groupName: string) => {
    setPreselectedGroup(groupName);
    setShowNewRuleModal(true);
  };

  const filteredRules = useMemo(() => {
    if (!searchTerm) return rules;

    const searchLower = searchTerm.toLowerCase();
    return rules.filter(rule => {
      if (selectedGroup && rule['Account Group Name'] !== selectedGroup) {
        return false;
      }

      const searchableFields = {
        accountGroup: String(rule['Account Group Name'] || '').toLowerCase(),
        testTable: String(rule.Test_Table || '').toLowerCase(),
        detectionIndicator: String(rule.Detection_Indicator || '').toLowerCase(),
        testColumn: String(rule.Test_Column || '').toLowerCase(),
        testValue: String(rule.Test_Value || '').toLowerCase(),
        friendlyName: getFriendlyName(rule['Account Group Name'], friendlyNames)?.toLowerCase() || ''
      };

      return Object.values(searchableFields).some(value => value.includes(searchLower));
    });
  }, [rules, searchTerm, selectedGroup, friendlyNames]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="text-lg text-gray-600">Loading test methods...</div>
      </div>
    );
  }

  if (s3Error) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="text-lg text-red-600">Error loading test methods: {s3Error}</div>
      </div>
    );
  }

  return (
    <div className="flex h-full">
      <div className="flex flex-col flex-grow p-6 transition-all duration-300">
        <div className="mb-6">
          <h2 className="text-xl font-semibold mb-2">Identify Reservations</h2>
          <p className="text-gray-600">
            Configure rules to identify which reservations should be associated with each account group.
          </p>
        </div>

        {error && (
          <div className="mb-4 p-3 bg-red-100 border border-red-200 rounded-md flex items-center text-red-700">
            <AlertCircle className="w-5 h-5 mr-2" />
            {error}
          </div>
        )}

        <div className="flex flex-col flex-grow bg-white rounded-lg shadow">
          <div className="p-4 border-b border-gray-200">
            <div className="flex justify-between items-center mb-4">
              <div className="relative flex-1 max-w-md">
                <input
                  type="text"
                  placeholder="Search rules..."
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    if (!e.target.value) {
                      setSelectedGroup(null);
                    }
                  }}
                  className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                />
                <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                {selectedGroup && (
                  <button
                    onClick={() => {
                      setSelectedGroup(null);
                      setSearchTerm('');
                    }}
                    className="absolute right-2 top-2 p-1 hover:bg-gray-100 rounded-full"
                  >
                    <X className="w-4 h-4 text-gray-500" />
                  </button>
                )}
              </div>
              <button
                onClick={() => setShowNewRuleModal(true)}
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
              >
                Add Rule
              </button>
            </div>
          </div>

          <div className="overflow-auto flex-grow">
            <table className="w-full">
              <thead className="bg-gray-50 sticky top-0">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Account Group
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Test Table
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Detection Indicator
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Test Column
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Test Value
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredRules.map((rule) => (
                  <tr
                    key={rule.id}
                    onClick={() => setSelectedRule(selectedRule?.id === rule.id ? null : rule)}
                    className={`transition-colors duration-150 cursor-pointer ${
                      selectedRule?.id === rule.id ? 'bg-blue-50' : 'hover:bg-gray-50'
                    }`}
                  >
                    <td className="px-6 py-4">
                      <select
                        value={rule['Account Group Name']}
                        onChange={(e) => updateRule(rule.id, { 'Account Group Name': e.target.value })}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                      >
                        <option value="">Select Account Group</option>
                        {finalGroups.map(group => (
                          <option key={group.name} value={group.name}>
                            {group.name} {getFriendlyName(group.name, friendlyNames) ? 
                              `(${getFriendlyName(group.name, friendlyNames)})` : ''}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td className="px-6 py-4">
                      <select
                        value={rule.Test_Table}
                        onChange={(e) => updateRule(rule.id, { 
                          Test_Table: e.target.value as 'Sales Batch Method' | 'QT Data Method',
                          Test_Column: testMethods[e.target.value]?.fields[0]?.column_name || '',
                          Test_Value: ''
                        })}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                      >
                        <option value="Sales Batch Method">Sales Batch Method</option>
                        <option value="QT Data Method">QT Data Method</option>
                      </select>
                    </td>
                    <td className="px-6 py-4">
                      <select
                        value={rule.Detection_Indicator}
                        onChange={(e) => updateRule(rule.id, { 
                          Detection_Indicator: e.target.value as 'Revenue' | 'Vendor Cost' | 'Commission'
                        })}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                      >
                        <option value="">Select Indicator</option>
                        <option value="Revenue">Revenue</option>
                        <option value="Vendor Cost">Vendor Cost</option>
                        <option value="Commission">Commission</option>
                      </select>
                    </td>
                    <td className="px-6 py-4">
                      <select
                        value={rule.Test_Column}
                        onChange={(e) => updateRule(rule.id, { Test_Column: e.target.value })}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 group"
                        title={getFieldDescription(rule.Test_Table, rule.Test_Column)}
                      >
                        <option value="">Select Test Column</option>
                        {testMethods[rule.Test_Table]?.fields.map(field => (
                          <option 
                            key={field.column_name} 
                            value={field.column_name}
                            title={field.description}
                          >
                            {field.column_name}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td className="px-6 py-4">
                      {testMethods[rule.Test_Table]?.fields.find(f => f.column_name === rule.Test_Column)?.field_type === 'Dropdown' ? (
                        <select
                          value={rule.Test_Value}
                          onChange={(e) => updateRule(rule.id, { Test_Value: e.target.value })}
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        >
                          <option value="">Select Value</option>
                          {testMethods[rule.Test_Table]?.fields
                            .find(f => f.column_name === rule.Test_Column)
                            ?.options?.map(option => (
                              <option 
                                key={option.value} 
                                value={option.value}
                                title={option.info}
                              >
                                {option.label}
                              </option>
                            ))}
                        </select>
                      ) : (
                        <input
                          type="text"
                          value={rule.Test_Value}
                          onChange={(e) => updateRule(rule.id, { Test_Value: e.target.value })}
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        />
                      )}
                    </td>
                    <td className="px-6 py-4">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteRule(rule.id);
                        }}
                        className="text-red-600 hover:text-red-900"
                      >
                        <Trash2 className="w-5 h-5" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {!previewOpen && (
        <button
          onClick={() => setPreviewOpen(true)}
          className="fixed right-0 top-1/2 transform -translate-y-1/2 bg-white border border-gray-200 rounded-l-lg p-2 shadow-lg z-[999] flex flex-col items-center justify-center min-h-[80px] w-[40px]"
        >
          <ChevronLeft className="w-5 h-5" />
          <span className="writing-mode-vertical text-sm mt-2">Summary</span>
        </button>
      )}

      {previewOpen && (
        <SummaryPane
          configuredGroups={configuredGroups}
          unconfiguredGroups={unconfiguredGroups}
          onGroupClick={handleGroupClick}
          onClose={() => setPreviewOpen(false)}
          selectedGroup={selectedGroup}
          onAddRule={handleAddRule}
          accountGroups={groupsWithFriendlyNames}
        />
      )}

      {showNewRuleModal && (
        <NewIdentificationRuleModal
          onClose={() => {
            setShowNewRuleModal(false);
            setPreselectedGroup(null);
          }}
          onSave={addRule}
          accountGroups={finalGroups.map(g => g.name)}
          methods={testMethods}
          preselectedGroup={preselectedGroup}
        />
      )}
    </div>
  );
};

export default IdentifyReservations;