import { Account, Rule, AccountGroup } from '../types';

export const generateGroupName = (
  account: Account,
  rule?: Rule
): string => {
  const prefix = account['Revenue Or Expense'];
  
  if (rule?.Override) {
    // When override is provided, still prefix with Revenue/Expense
    return `${prefix}-${rule.Override}`;
  }
  
  const type = rule?.['Allocate Based On'] === 'Account ID' ? 'AID' : 'S02';
  const value = rule?.['Allocation Value'].toString() || account['Segment 2 ID'].toString();
  
  return `${prefix}-${type}-${value}`;
};

export const groupAccounts = (
  accounts: Account[],
  rules: Rule[]
): AccountGroup[] => {
  const groups: Map<string, AccountGroup> = new Map();

  accounts.forEach(account => {
    // Find all matching rules for this account
    const matchingRules = rules.filter(rule => {
      if (rule['Allocate Based On'] === 'Account ID') {
        return rule['Allocation Value'].toString() === account['Account ID'].toString();
      } else {
        return rule['Allocation Value'].toString() === account['Segment 2 ID'].toString();
      }
    });

    // Prioritize Account ID rules over Segment 2 rules
    const appliedRule = matchingRules.find(r => r['Allocate Based On'] === 'Account ID') ||
                       matchingRules.find(r => r['Allocate Based On'] === 'Segment 2');

    // Get other rules that weren't applied
    const otherMatchingRules = matchingRules.filter(rule => {
      if (!appliedRule) return true;
      return rule.id !== appliedRule.id;
    });

    // Generate group name
    const groupName = generateGroupName(account, appliedRule);

    // Add to group
    if (!groups.has(groupName)) {
      groups.set(groupName, {
        name: groupName,
        accounts: [],
        rule: appliedRule,
        isDefault: !appliedRule
      });
    }

    // Add account with rule information
    const enrichedAccount = {
      ...account,
      appliedRule,
      otherMatchingRules
    };
    
    groups.get(groupName)!.accounts.push(enrichedAccount);
  });

  return Array.from(groups.values());
};